import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  IconButton,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  collection,
  query,
  where,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";

const EnrollmentPopup = ({ open, handleClose, classObj }) => {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [enrolledStudents, setEnrolledStudents] = useState({});
  const [loading, setLoading] = useState(false);

  const class_uuid = classObj.class_uuid;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getEnrolledStudentEmails();
        fetchEnrolledStudents(class_uuid);
      } else {
        console.log("Session Expired");
      }
    });
  }, []);

  const getEnrolledStudentEmails = async () => {
    const enrolledEmailsRef = query(
      collection(db, "users"),
      where("role", "==", "student")
    );

    // Set up a listener to get real-time updates
    const unsubscribe = onSnapshot(enrolledEmailsRef, (querySnapshot) => {
      const studentList = [];
      querySnapshot.forEach((doc) => {
        studentList.push(doc.data());
      });
      // Sort the students alphabetically by name
      studentList.sort((a, b) => {
        const nameA = `${a.firstname} ${a.lastname}`.toLowerCase();
        const nameB = `${b.firstname} ${b.lastname}`.toLowerCase();
        return nameA.localeCompare(nameB);
      });
      setStudentList(studentList);
    });
    return unsubscribe;
  };
      

  const fetchEnrolledStudents = async (class_uuid) => {
    const q = query(
      collection(db, "classes"),
      where("class_uuid", "==", class_uuid)
    );
    // Set up a listener to get real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setEnrolledStudents(doc.data());
      });
    });
    return unsubscribe;
  };

  const handleCloseDialog = () => {
    setSelectedStudents([]);
    setEmailError("");
    handleClose();
  };

  const handleDeleteUser = (index) => {
    setSelectedStudents((prevStudents) =>
      prevStudents.filter((_, i) => i !== index)
    );
  };

  const handleSubmitDialog = async () => {
    setLoading(true); // Start loading

    const successEmails = [];
    const failureEmails = [];

    if (selectedStudents.length === 0) {
      alert("Please select students before enrolling");
      setLoading(false); // Stop loading
      return;
    }

    const promises = selectedStudents.map(async (student) => {
      const email = student.email;
      const userId = student.uuid;
      const studentName = `${student.firstname} ${student.lastname}`;

      try {
        const classDocRef = doc(db, "classes", class_uuid);
        const classUpdate = {
          student_uuids: arrayUnion(userId),
          [`students.${userId}`]: {
            name: studentName,
            status: "Enrolled",
            joinDate: new Date().toISOString().split("T")[0],
            classAssistant: false,
          },
        };

        const homeworkUpdates = classObj.homeworks.map(async (homeworkId) => {
          const homeworkRef = doc(db, "homeworks", homeworkId);
          const homeworkData = await getDoc(homeworkRef);

          if (homeworkData.exists()) {
            await updateDoc(homeworkRef, {
              student_uuids: arrayUnion(userId),
              [`students.${userId}`]: {
                name: studentName,
                homework_status: "Pending",
                homework_grade: "Pending",
              },
            });
          } else {
            console.error(`Homework with UUID ${homeworkId} does not exist.`);
          }
        });

        // Execute the class update and homework updates in parallel
        await Promise.all([
          updateDoc(classDocRef, classUpdate),
          ...homeworkUpdates,
        ]);

        successEmails.push(email);
      } catch (error) {
        console.error("Error enrolling student:", error);
        failureEmails.push(email);
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    setLoading(false); // Stop loading

    if (failureEmails.length === 0) {
      alert("All emails enrolled successfully.");
    } else if (successEmails.length === 0) {
      alert("Failed to enroll any emails.");
    } else {
      alert(
        `Successfully enrolled: ${successEmails.join(
          ", "
        )}\nFailed to enroll: ${failureEmails.join(", ")}`
      );
    }

    // Close the dialog
    handleCloseDialog();
  };

  const truncateEmail = (email) => {
    const maxLength = 25; // Set the maximum length before truncating
    return email.length > maxLength ? email.slice(0, maxLength) + "..." : email;
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle>Enroll Students</DialogTitle>
      <DialogContent style={{ minWidth: "200px" }}>
        <div
          style={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
        >
          <Autocomplete
            multiple
            options={studentList}
            freeSolo
            fullWidth
            disableCloseOnSelect
            value={selectedStudents}
            onChange={(event, newValue) => {
              setSelectedStudents(newValue);
            }}
            getOptionLabel={(option) => option.email || ""}
            renderOption={(props, option) => {
              const isEnrolled = enrolledStudents.student_uuids?.includes(
                option.uuid
              );
              return (
                <li
                  {...props}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    opacity: isEnrolled ? 0.5 : 1,
                    pointerEvents: isEnrolled ? "none" : "auto", // Disable interaction if enrolled
                  }}
                >
                  <div>
                    {option.firstname} {option.lastname}
                    <br />
                    <small>{option.email}</small>
                  </div>
                  {isEnrolled && (
                    <div
                      style={{
                        marginLeft: "auto",
                        color: "Green",
                        fontWeight: "normal",
                      }}
                    >
                      Enrolled
                    </div>
                  )}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enter or Select Email"
                variant="outlined"
                fullWidth
                error={Boolean(emailError)}
                helperText={emailError}
              />
            )}
          />
        </div>
        <List>
          {selectedStudents.map((student, index) => (
            <React.Fragment key={index}>
              <ListItem
                sx={{ paddingLeft: 0, paddingRight: 0 }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteUser(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>{student.email.charAt(0).toUpperCase()}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={truncateEmail(student.email)} />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="secondary">
          Close
        </Button>
        <Button
          sx={{ width: "70px" }}
          onClick={handleSubmitDialog}
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Enroll"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EnrollmentPopup;
