import React, { useState } from "react";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";
import {
  doc,
  updateDoc,
  setDoc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  arrayRemove,
  writeBatch,
} from "firebase/firestore";
import { db, storage } from "../firebase/firebase";
import { v4 as uuidv4 } from "uuid";
import PopUp from "../components/popup";

function Tools() {
  const [loading, setLoading] = useState(false);
  const [openDeleteStudent, setOpenDeleteStudent] = useState(false);
  const [deleteStudentLoading, setDeleteStudentLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    validateEmail(value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  const handleDeleteStudent = async () => {
    setDeleteStudentLoading(true);
    console.log(`Deleting student with email: ${email}`);
    try {
      const userQuery = query(
        collection(db, "users"),
        where("email", "==", email),
        where("status", "==", "active")
      );
      const userSnapshot = await getDocs(userQuery);
      if (!userSnapshot.empty) {
        let userUUID = null;

        const userBatch = writeBatch(db);
        userSnapshot.forEach((docSnapshot) => {
          const userDoc = docSnapshot.data();
          userUUID = userDoc.uuid; // Get the UUID of the user
          console.log(`User UUID: ${userUUID}`); // Do something with the UUID if needed

          userBatch.delete(doc(db, "users", docSnapshot.id));
        });
        await userBatch.commit();

        if (userUUID) {
          // Handle classes collection
          const classQuery = query(
            collection(db, "classes"),
            where("student_uuids", "array-contains", userUUID)
          );
          const classSnapshot = await getDocs(classQuery);

          const classBatch = writeBatch(db);
          classSnapshot.forEach((classDoc) => {
            const classData = classDoc.data();
            const classRef = doc(db, "classes", classDoc.id);

            // Remove userUUID from student_uuids array
            classBatch.update(classRef, {
              student_uuids: arrayRemove(userUUID),
            });

            // Remove userUUID from students map
            if (classData.students && classData.students[userUUID]) {
              const updatedStudents = { ...classData.students };
              delete updatedStudents[userUUID];
              classBatch.update(classRef, {
                students: updatedStudents,
              });
            }
          });
          await classBatch.commit();
          console.log(`Deleted user from classes with UUID: ${userUUID}`);

          // Handle homeworks collection
          const homeworkQuery = query(
            collection(db, "homeworks"),
            where("student_uuids", "array-contains", userUUID)
          );
          const homeworkSnapshot = await getDocs(homeworkQuery);

          const homeworkBatch = writeBatch(db);
          homeworkSnapshot.forEach((homeworkDoc) => {
            const homeworkData = homeworkDoc.data();
            const homeworkRef = doc(db, "homeworks", homeworkDoc.id);

            // Remove userUUID from student_uuids array
            homeworkBatch.update(homeworkRef, {
              student_uuids: arrayRemove(userUUID),
            });

            // Remove userUUID from students map
            if (homeworkData.students && homeworkData.students[userUUID]) {
              const updatedStudents = { ...homeworkData.students };
              delete updatedStudents[userUUID];
              homeworkBatch.update(homeworkRef, {
                students: updatedStudents,
              });
            }
          });
          await homeworkBatch.commit();
          console.log(`Deleted user from homeworks with UUID: ${userUUID}`);

          // Handle submissions collection
          const submissionQuery = query(
            collection(db, "submissions"),
            where("studentUuid", "==", userUUID)
          );
          const submissionSnapshot = await getDocs(submissionQuery);

          const submissionBatch = writeBatch(db);
          submissionSnapshot.forEach((submissionDoc) => {
            submissionBatch.delete(doc(db, "submissions", submissionDoc.id));
          });
          await submissionBatch.commit();
          console.log(`Deleted user submissions with UUID: ${userUUID}`);
        }

        alert(`Deleted user with email: ${email}`);
        setDeleteStudentLoading(false);
      } else {
        alert(`No active user found with email: ${email}`);
        setDeleteStudentLoading(false);
      }
      setOpenDeleteStudent(false);
    } catch (error) {
      console.error("Error deleting user: ", error);
    }
  };

  const fetchAudioDuration = async (fileUrl) => {
    console.log(fileUrl);
    return new Promise((resolve, reject) => {
      const audio = new Audio(fileUrl);
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
      audio.onerror = reject;
    });
  };

  const runScripts = async () => {
    setLoading(true); // Set loading to true while running scripts
    try {
      const storageRef = ref(storage);
      const teacherFolders = await listAll(storageRef);
      const teacherIds = teacherFolders.prefixes.map((prefix) => prefix.name);
      console.log("Teacher IDs:", teacherIds);

      for (const teacherPrefix of teacherFolders.prefixes) {
        if (!teacherPrefix.name || teacherPrefix.name === "undefined") continue;
        const classFolders = await listAll(teacherPrefix);
        const classIds = classFolders.prefixes.map((prefix) => prefix.name);
        console.log(`Class IDs for teacher ${teacherPrefix.name}:`, classIds);

        for (const classPrefix of classFolders.prefixes) {
          if (!classPrefix.name || classPrefix.name === "undefined") continue;
          const homeworkFolders = await listAll(classPrefix);
          const homeworkIds = homeworkFolders.prefixes.map(
            (prefix) => prefix.name
          );
          console.log(
            `Homework IDs for class ${classPrefix.name}:`,
            homeworkIds
          );

          for (const homeworkPrefix of homeworkFolders.prefixes) {
            const homeworkId = homeworkPrefix.name.split("/").pop();

            // Skip processing for 'attachments' and 'undefined' folders
            if (
              !homeworkId ||
              homeworkId === "attachments" ||
              homeworkId === "undefined"
            )
              continue;

            const studentFolders = await listAll(homeworkPrefix);

            for (const studentPrefix of studentFolders.prefixes) {
              const studentId = studentPrefix.name.split("/").pop();

              // Skip processing for 'attachments' and 'undefined' students
              if (
                !studentId ||
                studentId === "attachments" ||
                studentId === "undefined"
              )
                continue;

              const files = await listAll(studentPrefix);
              const fileIds = files.items.map((item) => item.name);
              console.log(
                `File names for student ${studentPrefix.name}:`,
                fileIds
              );

              // Fetch homework document and student document once per student folder
              const homeworkDocRef = doc(db, "homeworks", homeworkId);
              const homeworkDocSnap = await getDoc(homeworkDocRef);
              const homeworkObj = homeworkDocSnap.exists()
                ? homeworkDocSnap.data()
                : null;

              const studentDocRef = doc(db, "users", studentId);
              const studentDocSnap = await getDoc(studentDocRef);
              const userInfo = studentDocSnap.exists()
                ? studentDocSnap.data()
                : null;

              if (homeworkObj && userInfo) {
                // Process each file for the student
                await Promise.all(
                  files.items.map(async (file) => {
                    const fileUuid = uuidv4(); // Generate UUID for the file
                    const url = await getDownloadURL(file);
                    const duration = await fetchAudioDuration(url);
                    const referenceFileDuration = homeworkObj.attachments?.[0]
                      ?.url
                      ? await fetchAudioDuration(homeworkObj.attachments[0].url)
                      : null;
                    const metadata = await getMetadata(file);

                    // Update Firestore with file data
                    await updateDoc(homeworkDocRef, {
                      [`students.${studentId}.files.${fileUuid}`]: {
                        name: file.name,
                        url: url,
                        grade: null,
                        duration: duration,
                        created_date: metadata.timeCreated,
                      },
                    });

                    // Add new submission document
                    await setDoc(doc(db, "submissions", fileUuid), {
                      fileUuid: fileUuid,
                      homeworkUid: homeworkId,
                      classUid: classPrefix.name.split("/").pop(),
                      studentFileName: file.name,
                      studentFileUrl: url,
                      referenceFileName:
                        homeworkObj.attachments?.[0]?.name || "",
                      referenceFileUrl: homeworkObj.attachments?.[0]?.url || "",
                      studentFileDuration: duration,
                      referenceFileDuration: referenceFileDuration,
                      studentName: `${userInfo.firstname} ${userInfo.lastname}`,
                      studentUuid: studentId,
                      gender: userInfo.gender,
                      createdDate: metadata.timeCreated,
                    });
                  })
                );
              }
            }
          }
        }
      }
      console.log("Script execution completed successfully.");
    } catch (error) {
      console.error("Error running scripts:", error);
    } finally {
      setLoading(false); // Set loading to false when scripts are finished
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        height="100vh" // Ensure the Box takes the full height of the viewport
      >
        <Button
          variant="contained"
          color="primary"
          onClick={runScripts}
          disabled={loading} // Disable button when scripts are running
        >
          Update firestore database and submission
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenDeleteStudent(true);
            setEmail("");
            setIsEmailValid(true);
          }}
        >
          Delete Student
        </Button>
      </Box>

      {loading && (
        <PopUp maxWidth="xs" title="Update Submission" setOpen={setLoading}>
          <div style={{ textAlign: "center" }}>
            <CircularProgress style={{ color: "black" }} />
          </div>
        </PopUp>
      )}
      {openDeleteStudent && (
        <PopUp
          maxWidth="xs"
          title="Delete student"
          setOpen={setOpenDeleteStudent}
        >
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            fullWidth
            margin="normal"
            variant="outlined"
            error={!isEmailValid}
            helperText={!isEmailValid && "Please enter a valid email address"}
          />
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={handleDeleteStudent}
            disabled={!email || !isEmailValid} // Disable button if email is empty or invalid
            style={{ marginTop: "20px" }}
          >
            {deleteStudentLoading ? <CircularProgress size={20} /> : "Delete"}
          </Button>
        </PopUp>
      )}
    </>
  );
}

export default Tools;
