import React, { useState, useEffect } from "react";
import "./teacherHomeworkDashboard.css";
import { Grid, Button, Typography, Box, CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import NavBar from "../../components/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeworkCard from "../../components/homework_card";
import {
  doc,
  setDoc,
  updateDoc,
  query,
  where,
  onSnapshot,
  deleteDoc,
  getDoc,
  getDocs
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import HomeworkPopup from "../../components/homework_popup";
import { auth, db, storage } from "../../firebase/firebase";
import {
  ref,
  deleteObject,
  listAll,
} from "firebase/storage";
import { collection } from "firebase/firestore";
import { format } from "date-fns";

function TeacherHomeworkDashboard() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [homeworks, setHomeworks] = useState([]);
  const [teacherUid, setTeacherUid] = useState("");
  const [classUid, setClassUid] = useState("");
  const [classObject, setClassObject] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false); // For form submission
  const [loadingHomeworks, setLoadingHomeworks] = useState(true);
  const [homeworkUid, setHomeworkUid] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setDisplayName(user.displayName);
        setTeacherUid(user.uid);
        setClassUid(location.state.class_uuid);
        setClassObject(location.state.class_obj);
        fetchHomeworks(location.state.class_uuid);
      } else {
        console.log("Session Expired");
      }
    });
    // eslint-disable-next-line
  }, []);

  const fetchHomeworks = async (class_uuid) => {
    setLoadingHomeworks(true);
    const q = query(
      collection(db, "homeworks"),
      where("class_uuid", "==", class_uuid)
    );
    // Set up a listener to get real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const homeworksData = [];
      querySnapshot.forEach((doc) => {
        homeworksData.push(doc.data());
      });
      homeworksData.sort((a, b) => a.homework_title.localeCompare(b.homework_title));
      setHomeworks(homeworksData);
      setLoadingHomeworks(false);
    });
    return unsubscribe;
  };

  const handleAddHomework = () => {
    setHomeworkUid(uuidv4());
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleAddHomeworkSubmit = async (homeworkDetails) => {
    setLoading(true);
  
    // Create a new homework object with the generated UUID
    const newHomeworkDetails = {
      ...homeworkDetails,
      homework_uuid: homeworkUid,
    };
    // Call pushDb function
    return pushDb(newHomeworkDetails) // Ensure pushDb returns a Promise
      .then(() => {
        handlePopupClose();
      })
      .finally(() => setLoading(false));
  };  
  
  const handleDelete = async (homework_uuid) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this homework?"
      );
      if (confirmed) {
        // 1. Delete teacher attachments
        const teacherFolderRef = ref(
          storage,
          `${teacherUid}/${classUid}/${homework_uuid}/attachments`
        );
        const teacherItems = await listAll(teacherFolderRef);
        const teacherDeletePromises = teacherItems.items.map((item) => deleteObject(item));
        await Promise.all(teacherDeletePromises);

        // 2. Delete student attachments
        const studentFolderRef = ref(
          storage,
          `${teacherUid}/${classUid}/${homework_uuid}`
        );
        const studentItems = await listAll(studentFolderRef);
        const studentDeletePromises = studentItems.prefixes.map(async (prefix) => {
          const studentFiles = await listAll(prefix);
          const fileDeletePromises = studentFiles.items.map((item) => deleteObject(item));
          await Promise.all(fileDeletePromises);
        });
        await Promise.all(studentDeletePromises);

        // 3. Delete homework document from Firestore
        await deleteDoc(doc(db, "homeworks", homework_uuid));

        // 4. Update the class document to remove the homework UUID
        const classRef = doc(db, "classes", classUid);
        const classDoc = await getDoc(classRef);
        if (classDoc.exists()) {
          const classData = classDoc.data();
          const updatedHomeworks = classData.homeworks.filter(
            (h) => h !== homework_uuid
          );
          await updateDoc(classRef, { homeworks: updatedHomeworks });
        }

        // 5. Delete submissions where homework_uuid is homeworkUid
        const submissionsQuery = query(
          collection(db, "submissions"),
          where("homeworkUid", "==", homework_uuid)
        );
        const submissionsSnapshot = await getDocs(submissionsQuery);
        const deleteSubmissionsPromises = submissionsSnapshot.docs.map((doc) =>
          deleteDoc(doc.ref)
        );
        await Promise.all(deleteSubmissionsPromises);

        console.log("Homework deleted successfully.");
      } else {
        console.log("Deletion cancelled.");
      }
    } catch (error) {
      console.error("Error deleting homework:", error);
    }
  };

  const pushDb = async (data) => {
    try {
      const formattedStartDate = format(new Date(data.startDate), "yyyy-MM-dd");
      const formattedEndDate = format(new Date(data.endDate), "yyyy-MM-dd");
      const currentDate = new Date().toISOString().split("T")[0];
      const classRef = doc(db, "classes", classUid);
      const classData = await getDoc(classRef);
      
      if (!classData.exists()) {
        throw new Error("Class data does not exist");
      }
  
      const students = {};
      const student_uuids = []; // Array to hold student UUIDs
  
      for (const student of classData.data().student_uuids) {
        const studentData = await getDoc(doc(db, "users", student));
        
        if (studentData.exists()) {
          students[student] = {
            student_uuid: student,
            homework_status: "Pending",
            homework_grade: "Pending",
            name: studentData.data().firstname + " " + studentData.data().lastname,
          };
          student_uuids.push(student); // Add each student UUID to the array
        } else {
          console.error(`Student with UUID ${student} does not exist.`);
        }
      }
  
      await setDoc(doc(db, "homeworks", data.homework_uuid), {
        class_uuid: classUid,
        teacher_uuid: teacherUid,
        homework_uuid: data.homework_uuid,
        homework_title: data.title,
        homework_description: data.description,
        homework_start_date: formattedStartDate,
        homework_end_date: formattedEndDate,
        total_grade: data.totalGrade,
        attachments: data.attachments.map(att => ({ name: att.name, type:att.type, url: att.url })),
        homework_status: "Ongoing",
        homework_created_date: currentDate,
        student_uuids: student_uuids,
        students: students,
      });
  
      const newHomeworkIds = [...classData.data().homeworks, data.homework_uuid];
      await updateDoc(classRef, { homeworks: newHomeworkIds });
  
    } catch (error) {
      console.error("Error pushing data to DB:", error);
      throw error; // Rethrow error to be caught in handleAddHomeworkSubmit
    }
  };  

  return (
    <div className="thd-container">
      <div className="thd-navbar">
        <NavBar name={displayName} role={"Teacher"} />
        </div>
        <div className="arrowback">
          <Button
          tabIndex={-1}
          startIcon={<ArrowBackIcon />}
          className="arrowback-icon"
          style={{color:"black", padding:"24px"}}
          onClick={() => navigate("/teacherDashboard")}
        >
          Teacher Dashboard
        </Button>
        </div>
        <div className="thd-add-homework-btn-holder">
          <Button
            className="thd-add-homework-btn"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddHomework}
            style={{ marginRight: "10px" }}
          >
            Add Homework
          </Button>
        </div>
      <div className="thd-homework-card">
        {loadingHomeworks ? (
          <CircularProgress style={{ color: "white", marginLeft: "50%" }} />
        ) : (
          homeworks.length === 0 ? (
            <Box textAlign="center" mt={5}>
              <Typography variant="h5" style={{ margin: "20px" }}>
                No homeworks yet...
              </Typography>
            </Box>
          ) : (
            <Grid style={{ marginLeft: "20px", marginRight: "20px" }} item xs={9}>
              <Grid container spacing={2}>
                {homeworks.map((hms) => (
                  <Grid key={hms.homework_uuid} item xs={12} sm={6} md={4} lg={3}>
                    <HomeworkCard
                      role={"teacher"}
                      homeworkObj={hms}
                      handleDelete={handleDelete}
                      classObject={classObject}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )
        )}
      </div>
      <HomeworkPopup
        isOpen={isPopupOpen}
        onClose={handlePopupClose}
        onSubmit={handleAddHomeworkSubmit}
        classObj={classObject}
        loading={loading}
        setLoading={setLoading}
        homeworkUUID={homeworkUid}
      />
    </div>
  );
  }

export default TeacherHomeworkDashboard;
