import React, { useState, useEffect } from "react";
import "./profile.css";
import CustomDropdrown from "../../components/dropdown";
import {
  applyActionCode,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { db, auth } from "../../firebase/firebase";
import CustomCheckbox from "../../components/checkbox";

function Profile() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [role, setRole] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  function getParameterByName(name) {
    let url = window.location.href;
    console.log(url);
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const verifyEmail = () => {
    const actionCode = getParameterByName("oobCode");
    console.log(actionCode);
    applyActionCode(auth, actionCode)
      .then((resp) => {
        console.log("verified");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    verifyEmail();
    // eslint-disable-next-line
  }, []);

  const genderItems = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Prefer Not To Say" },
  ];

  const countryCodeItems = [
    { value: "+1", label: "+1" },
    { value: "+91", label: "+91" },
  ];

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const validator = () => {
    if (firstname === "") {
      setError("*Please Enter Your First Name");
    } else if (lastname === "") {
      setError("*Please Enter Your Last Name");
    } else if (role === "") {
      setError("*Please Select Your Role");
    } else if (gender === "") {
      setError("*Please Select Your Gender");
    } else if (!checked) {
      setError("*Please Accept The Terms and Conditions");
    } else {
      setError("*Please Try Again Later");
    }
  };

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleRoleChange = (selectedRole) => {
    setRole(selectedRole);
  };

  const handleSubmit = async () => {
    if (
      firstname !== "" &&
      lastname !== "" &&
      gender !== "" &&
      role !== "" &&
      checked
    ) {
      setError("");
      console.log(auth.currentUser.uid);
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      console.log(data);
      pushDb(data, auth.currentUser.uid);
      if (role === "teacher") {
        navigate("/teacherDashboard");
      } else {
        navigate("/studentDashboard");
      }
    } else {
      validator();
    }
  };

  const pushDb = async (data, uid) => {
    console.log(data);
    console.log(uid);
    console.log(
      firstname +
        " " +
        lastname +
        " " +
        countryCode +
        " " +
        mobile +
        " " +
        gender +
        " " +
        role +
        " " +
        data.email
    );
    await setDoc(doc(db, "users", uid), {
      firstname: firstname,
      lastname: lastname,
      countryCode: countryCode,
      mobile: mobile,
      email: data.email,
      status: "active",
      gender: gender,
      role: role,
      uuid: uid,
      verified: "true",
    });
  };

  return (
    <div className="profile-container">
      <div className="profile-card">
        <p className="profile-error">{error}</p>
        <h1 className="profile-logo">Embark</h1>
        <input
          className="profile-firstname"
          placeholder="Firstname"
          type="text"
          onChange={(e) => setFirstname(e.target.value)}
        />
        <input
          className="profile-lastname"
          placeholder="Lastname"
          type="text"
          onChange={(e) => setLastname(e.target.value)}
        />
        <div className="profile-mobile-field">
          <CustomDropdrown
            className="country-code-dropdown"
            value={countryCode}
            onChange={handleCountryCodeChange}
            label="Code"
            menuItems={countryCodeItems}
          />
          <input
            className="profile-mobile"
            placeholder="Mobile"
            type="text"
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>
        <div id="recaptcha"></div>
        <CustomDropdrown
          className="gender-dropdown"
          value={gender}
          onChange={handleGenderChange}
          label="Gender"
          menuItems={genderItems}
        />
        <div className="profile-role-field">
          <button
            className={`teacher-button ${role === "teacher" ? "selected" : ""}`}
            onClick={() => handleRoleChange("teacher")}
          >
            Teacher
          </button>
          <button
            className={`student-button ${role === "student" ? "selected" : ""}`}
            onClick={() => handleRoleChange("student")}
          >
            Student
          </button>
        </div>
        {/* <p className='profile-verify-text'>Verfied...</p> */}
        <div>
          <CustomCheckbox
            className={"terms-and-conditions-checkbox"}
            checked={checked}
            onChange={handleCheckChange}
            label={"I agree to the Terms and Conditions"}
          />
        </div>
        <button className="proceed-button" onClick={handleSubmit}>
          Proceed
        </button>
      </div>
    </div>
  );
}

export default Profile;
