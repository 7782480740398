import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Grid,
  CircularProgress,
} from "@mui/material";
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { v4 as uuidv4 } from "uuid";

function ClassPopup({ isOpen, onClose, onSubmit, loading, setLoading}) {
  const currentDate = new Date().toISOString().split('T')[0]; // Get current date in ISO format
  const [classDetails, setClassDetails] = useState({
    uuid: "",
    title: "",
    description: "",
    startDate: new Date(),
    endDate: (() => {
      const today = new Date();
      today.setMonth(today.getMonth() + 3);
      return today;
    })(),
    startTime: new Date().setHours(23, 55, 0, 0),
    endTime: new Date().setHours(23, 55, 0, 0),
    feeAmount: "125",
    feeCurrency: "USD",
    feeSchedule: "Monthly",
    classRecurrence: "Weekly",
    recurrence: [],
  });
  //const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    feeAmount: "",
    feeCurrency: "",
    feeSchedule: "",
    classRecurrence: "",
    recurrence: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClassDetails({ ...classDetails, [name]: value });
  };

  const handleDateChange = (name, date) => {
    setClassDetails({ ...classDetails, [name]: date });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    // Title validation
    if (!classDetails.title || classDetails.title.length > 50) {
      newErrors.title = "Title is required and should be maximum 50 characters";
      valid = false;
    } else {
      newErrors.title = "";
    }

    // Start Date validation
    if (!classDetails.startDate) {
      newErrors.startDate = "Start Date is required";
      valid = false;
    } else if (new Date(classDetails.startDate).setHours(0, 0, 0, 0) < new Date(currentDate).setHours(0, 0, 0, 0)) {
      console.log(classDetails.startDate);
      console.log(currentDate);
      newErrors.startDate = "Start Date must be today or LATER";
      valid = false;
    } else {
      newErrors.startDate = "";
    }

    // End Date validation
    if (!classDetails.endDate) {
      newErrors.endDate = "End Date is required";
      valid = false;
    } else if (new Date(classDetails.endDate).setHours(0, 0, 0, 0) < new Date(classDetails.startDate).setHours(0, 0, 0, 0)) {
      newErrors.endDate = "End Date must be after Start Date";
      valid = false;
    } else {
      newErrors.endDate = "";
    }
    
    // Start Time validation
    if (!classDetails.startTime) {
      newErrors.startTime = "Start Time is required";
      valid = false;
    } else {
      newErrors.startTime = "";
    }

  // End Time validation
  if (!classDetails.endTime) {
    newErrors.endTime = "End Time is required";
    valid = false;
  } else if (new Date(classDetails.endTime) < new Date(classDetails.startTime)) {
    newErrors.endTime = "End Time must be after Start Time";
    valid = false;
  } else {
    newErrors.endTime = "";
  }

    // Fee Amount validation
    if (!classDetails.feeAmount) {
      newErrors.feeAmount = "Fee Amount is REQUIRED";
      valid = false;
    } else {
      newErrors.feeAmount = "";
    }

    // Fee Currency validation
    if (!classDetails.feeCurrency) {
      newErrors.feeCurrency = "Fee Currency is required";
      valid = false;
    } else {
      newErrors.feeCurrency = "";
    }

    // Fee Schedule validation
    if (!classDetails.feeSchedule) {
      newErrors.feeSchedule = "Fee Schedule is required";
      valid = false;
    } else {
      newErrors.feeSchedule = "";
    }

    // Class Recurrence validation
    if (!classDetails.classRecurrence) {
      newErrors.classRecurrence = "Class Recurrence is required";
      valid = false;
    } else {
      newErrors.classRecurrence = "";
    }

    // Recurrence validation
    if (
      classDetails.classRecurrence === "Weekly" &&
      classDetails.recurrence.length === 0
    ) {
      newErrors.recurrence = "Recurrence is required for Weekly class";
      valid = false;
    } else {
      newErrors.recurrence = "";
    }

    setErrors(newErrors);
    return valid;
  };

  const clearFields = () => {
    setClassDetails({
      title: "",
      description: "",
      startDate: new Date(),
      endDate: (() => {
        const today = new Date();
        today.setMonth(today.getMonth() + 3);
        return today;
      })(),
      startTime: new Date().setHours(23, 55, 0, 0),
      endTime: new Date().setHours(23, 55, 0, 0),
      feeAmount: "120",
      feeCurrency: "USD",
      feeSchedule: "Monthly",
      classRecurrence: "Weekly",
      recurrence: [],
    });

    setErrors({
      title: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      feeAmount: "",
      feeCurrency: "",
      feeSchedule: "",
      classRecurrence: "",
      recurrence: "",
    });
  };

  const handleAddClassSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      const newClassDetails = { ...classDetails, uuid: uuidv4() };
      onSubmit(newClassDetails)
      .then(() => {
        clearFields();
        onClose();
      })
      .finally(() => setLoading(false));
  }
};

  const handleClose = () => {
    clearFields();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Add New Class</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          type="text"
          name="title"
          fullWidth
          value={classDetails.title}
          onChange={handleInputChange}
          error={Boolean(errors.title)}
          helperText={errors.title}
        />
        <TextField
          margin="dense"
          label="Description"
          type="text"
          name="description"
          multiline
          rows={3}
          fullWidth
          value={classDetails.description}
          onChange={handleInputChange}
        />
        <Grid item xs={6} style={{ height: '.5em' }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={classDetails.startDate ? new Date(classDetails.startDate) : null}
                label="Start Date"
                onChange={(date) => handleDateChange("startDate", date)}
                inputFormat="mm/dd/yyyy"
                InputLabelProps={{ shrink: true }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    name: 'startDate',
                    error: Boolean(errors.startDate),
                    helperText: errors.startDate,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={classDetails.endDate ? new Date(classDetails.endDate) : null}
                label="End Date"
                onChange={(date) => handleDateChange("endDate", date)}
                inputFormat="mm/dd/yyyy"
                InputLabelProps={{ shrink: true }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    name: 'endDate',
                    error: Boolean(errors.endDate),
                    helperText: errors.endDate,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ height: '.5em' }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                value={classDetails.startTime}
                label="Start time"
                onChange={(time) => handleDateChange("startTime", time)}
                InputLabelProps={{ shrink: true }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    name: 'startTime',
                    error: Boolean(errors.startTime),
                    helperText: errors.startTime,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                value={classDetails.endTime}
                label="End time"
                onChange={(time) => handleDateChange("endTime", time)}
                InputLabelProps={{ shrink: true }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    name: 'endTime',
                    error: Boolean(errors.endTime),
                    helperText: errors.endTime,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Fee Amount"
              type="number"
              name="feeAmount"
              fullWidth
              value={classDetails.feeAmount}
              onChange={handleInputChange}
              error={Boolean(errors.feeAmount)}
              helperText={errors.feeAmount}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              margin="dense"
              error={Boolean(errors.feeCurrency)}
            >
              <InputLabel>Fee Currency</InputLabel>
              <Select
                value={classDetails.feeCurrency}
                onChange={handleInputChange}
                name="feeCurrency"
                label="Fee Currency"
              >
                <MenuItem value="INR">INR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <FormControl
          fullWidth
          margin="dense"
          error={Boolean(errors.feeSchedule)}
        >
          <InputLabel>Fee Schedule</InputLabel>
          <Select
            value={classDetails.feeSchedule}
            onChange={handleInputChange}
            label="feeSchedule"
            name="feeSchedule"
          >
            <MenuItem value="Monthly">Monthly</MenuItem>
            <MenuItem value="Quarterly">Quarterly</MenuItem>
            <MenuItem value="Half-Yearly">Half-Yearly</MenuItem>
            <MenuItem value="Annually">Annually</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
          error={Boolean(errors.classRecurrence)}
        >
          <InputLabel>Class Recurrence</InputLabel>
          <Select
            value={classDetails.classRecurrence}
            onChange={handleInputChange}
            name="classRecurrence"
            label="classRecurrence"
          >
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
          </Select>
        </FormControl>
        {classDetails.classRecurrence === "Weekly" && (
          <FormControl
            fullWidth
            margin="dense"
            error={Boolean(errors.recurrence)}
          >
            <InputLabel>Recurrence</InputLabel>
            <Select
              multiple
              value={classDetails.recurrence}
              onChange={handleInputChange}
              name="recurrence"
              label="recurrence"
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="Sunday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Sunday")}
                />
                <ListItemText primary="Sunday" />
              </MenuItem>
              <MenuItem value="Monday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Monday")}
                />
                <ListItemText primary="Monday" />
              </MenuItem>
              <MenuItem value="Tuesday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Tuesday")}
                />
                <ListItemText primary="Tuesday" />
              </MenuItem>
              <MenuItem value="Wednesday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Wednesday")}
                />
                <ListItemText primary="Wednesday" />
              </MenuItem>
              <MenuItem value="Thursday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Thursday")}
                />
                <ListItemText primary="Thursday" />
              </MenuItem>
              <MenuItem value="Friday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Friday")}
                />
                <ListItemText primary="Friday" />
              </MenuItem>
              <MenuItem value="Saturday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Saturday")}
                />
                <ListItemText primary="Saturday" />
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          sx={{ width: "70px" }}
          onClick={handleAddClassSubmit}
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ClassPopup;
