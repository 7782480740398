import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CustomCheckbox = ({ className, checked, label, onChange }) => {
  return (
    <FormControlLabel
      className={className}
      control={<Checkbox checked={checked} onChange={onChange} />}
      label={label}
    />
  );
};

export default CustomCheckbox;
