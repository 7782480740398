import React, { useState, useEffect } from "react";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, CircularProgress, Grid, Typography, TextField,
    TableSortLabel, Box, InputAdornment, IconButton
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import dayjs from "dayjs";
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import NavBar from "../../components/navbar";
import { db } from "../../firebase/firebase";
import { useLocation } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import SearchIcon from '@mui/icons-material/Search';
import "./analyticsDashboard.css";

const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hrs}h ${mins}m ${secs}s`;
};

const descendingComparator = (a, b, orderBy) => {
    if (orderBy === 'time_in_seconds') {
        if (b[orderBy] === a[orderBy]) {
            // If durations are the same, compare by student name
            if (a.student_name.toLowerCase() < b.student_name.toLowerCase()) return -1;
            if (a.student_name.toLowerCase() > b.student_name.toLowerCase()) return 1;
            return 0;
        } else {
            return b[orderBy] - a[orderBy];
        }
    } else {
        if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
            const aLower = a[orderBy].toLowerCase();
            const bLower = b[orderBy].toLowerCase();
            if (bLower < aLower) return -1;
            if (bLower > aLower) return 1;
            return 0;
        } else {
            if (b[orderBy] < a[orderBy]) return -1;
            if (b[orderBy] > a[orderBy]) return 1;
            return 0;
        }
    }
};

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const headCells = [
    { id: 'student_name', numeric: false, disablePadding: false, label: 'Student Name' },
    { id: 'time_in_seconds', numeric: true, disablePadding: false, label: 'Practice Duration' }
];

const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell className="tableHeader">Rank</TableCell>
                {headCells.map((headCell) => (
                    <TableCell className="tableCell"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={{ ...visuallyHidden }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const StudentTable = () => {
    const location = useLocation();
    const role = location.state?.role;
    const [studentsData, setStudentsData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(true);
    const [dateRange, setDateRange] = useState([dayjs('2024-06-01'), dayjs()]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('time_in_seconds');
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchStudents = async () => {
            setLoading(true);
            try {
                const studentsQuery = query(collection(db, "users"), where("role", "==", "student"));
                const studentsSnapshot = await getDocs(studentsQuery);
                const students = studentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                const submissionsQuery = query(collection(db, "submissions"), where("createdDate", ">=", dateRange[0].toISOString()), where("createdDate", "<=", dateRange[1].toISOString()));
                const submissionsSnapshot = await getDocs(submissionsQuery);
                const submissions = submissionsSnapshot.docs.map(doc => doc.data());

                const studentsData = students.map(student => {
                    const studentSubmissions = submissions.filter(submission => submission.studentUuid === student.uuid);
                    const totalDuration = studentSubmissions.reduce((acc, submission) => acc + submission.studentFileDuration, 0);

                    return {
                        student_name: `${student.firstname} ${student.lastname}`,
                        time_in_seconds: totalDuration
                    };
                });

                // Sort students data to assign ranks based on duration
                const sortedStudentsData = stableSort(studentsData, getComparator('desc', 'time_in_seconds'));
                sortedStudentsData.forEach((student, index) => {
                    student.rank = index + 1;
                });

                setStudentsData(sortedStudentsData);
            } catch (error) {
                console.error("Error fetching students data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchStudents();
    }, [dateRange]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const filteredStudents = studentsData.filter(student =>
        student.student_name.toLowerCase().includes(searchQuery)
    );

    return (
        <div className="sad-container">
            <div className="sad-navbar">
            <NavBar role={role} />
            </div>
            <div className="container">
                <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ marginBottom: '20px' }}>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateRangePicker
                                startText="Start Date"
                                endText="End Date"
                                defaultValue={dateRange}
                                onChange={(newValue) => setDateRange(newValue)}
                                renderInput={(startProps, endProps) => (
                                    <>
                                        <TextField {...startProps} />
                                        <Typography sx={{ mx: 2 }}> - </Typography>
                                        <TextField {...endProps} />
                                    </>
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            label="Search by Student Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="searchTextField"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton className="searchIcon">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                {loading ? (
                    <Grid container justifyContent="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <TableContainer component={Paper} className="tableContainer">
                        <Table stickyHeader>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {stableSort(filteredStudents, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((student, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{student.rank}</TableCell>
                                            <TableCell>{student.student_name}</TableCell>
                                            <TableCell align="right">{formatTime(student.time_in_seconds)}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={filteredStudents.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                )}
            </div>
        </div>
    );
};

export default StudentTable;
