import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { db, storage } from "../firebase/firebase"; // Import Firebase storage
import { updateDoc, doc, getDoc, setDoc} from "firebase/firestore";
import {
  ref,
  getStorage,
  deleteObject,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";

function HomeworkEdit({ isOpen, onClose, homeworkObj, classObject }) {
  const currentDate = new Date().toISOString().split("T")[0];
  const [homeworkDetails, setHomeworkDetails] = useState({
    homework_uuid: "",
    homework_title: "",
    homework_description: "",
    homework_start_date: "",
    homework_end_date: "",
    total_grade: "",
    attachments: [],
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    startDate: "",
    endDate: "",
    totalGrade: "",
  });

  const [uploadProgress, setUploadProgress] = useState({});

  useEffect(() => {
    if (isOpen && homeworkObj) {
      const updatedAttachments = homeworkObj.attachments.map((attachment) => ({
        ...attachment,
        isNew: attachment.isNew ?? false,
      }));
      setHomeworkDetails({
        ...homeworkObj,
        homework_start_date: homeworkObj.homework_start_date
          ? new Date(homeworkObj.homework_start_date)
          : null,
        homework_end_date: homeworkObj.homework_end_date
          ? new Date(homeworkObj.homework_end_date)
          : null,
        attachments: updatedAttachments,
      });
    }
  }, [isOpen, homeworkObj]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHomeworkDetails({ ...homeworkDetails, [name]: value });
  };

  const handleDateChange = (name, date) => {
    setHomeworkDetails({ ...homeworkDetails, [name]: date });
  };

  const handleAttachmentChange = (event) => {
    const files = Array.from(event.target.files);
    const validTypes = ["audio/", "video/"];
  
    const invalidFiles = files.filter(
      (file) => !validTypes.some((type) => file.type.startsWith(type))
    );
  
    if (invalidFiles.length > 0) {
      alert("You can only upload audio or video files.");
      return;
    }
  
    const validFiles = files.filter((file) =>
      validTypes.some((type) => file.type.startsWith(type))
    );

    const existingAttachmentNames = homeworkDetails.attachments.map(
      (attachment) => attachment.name
    );
    const duplicateFiles = validFiles.filter((file) =>
      existingAttachmentNames.includes(file.name)
    );

    if (duplicateFiles.length > 0) {
      alert(
        `The following files already exist: ${duplicateFiles
          .map((file) => file.name)
          .join(", ")}`
      );
      return;
    }
    // New attachments to be uploaded
    const newAttachments = validFiles.map((file) => ({
      name: file.name,
      type: file.type,
      file: file,
      isNew: true,
    }));
  
    // Retain existing attachments and append new ones
    setHomeworkDetails((prevDetails) => ({
      ...prevDetails,
      attachments: [...prevDetails.attachments, ...newAttachments],
    }));
  };

  const uploadAttachments = (newAttachments) => {
    const storage = getStorage();
  
    return Promise.all(
      newAttachments.map((file) => {
        console.log("file details", file);
        if (file.isNew) {
          const storageRef = ref(storage, `${homeworkDetails.teacher_uuid}/${homeworkDetails.class_uuid}/${homeworkDetails.homework_uuid}/attachments/${file.name}`);
          const uploadTask = uploadBytesResumable(storageRef, file.file);
  
          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress((prevProgress) => ({
                  ...prevProgress,
                  [file.name]: progress,
                }));
              },
              (error) => {
                console.error("Upload failed:", error);
                reject(error);
              },
              async () => {
                try {
                  const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
  
                  // Update state
                  setHomeworkDetails((prevDetails) => {
                    console.log("prevDetails.attachments", prevDetails.attachments);
  
                    const updatedAttachments = prevDetails.attachments.map((attachment) => {
                      console.log("attachment details", attachment);
                      return attachment.name === file.name
                        ? { ...file, url: downloadURL, isNew: false }
                        : attachment.url
                        ? attachment
                        : { ...attachment, url: attachment.url };
                    });
                    return {
                      ...prevDetails,
                      attachments: updatedAttachments,
                    };
                  });
  
                  // Remove upload progress
                  setUploadProgress((prevProgress) => {
                    const { [file.name]: _, ...rest } = prevProgress;
                    return rest;
                  });
  
                  resolve({
                    name: file.name,
                    type: file.type,
                    url: downloadURL,
                  });
                } catch (error) {
                  console.error("Error getting download URL or updating Firestore:", error);
                  reject(error);
                }
              }
            );
          });
        } else {
          // If the file is not new, resolve immediately
          return Promise.resolve({
            name: file.name,
            type: file.type,
            url: file.url, // Use the existing URL
          });
        }
      })
    );
  };    

  const deleteAttachmentField = async (index) => {
    const newAttachments = [...homeworkDetails.attachments];
    const attachmentToDelete = newAttachments.splice(index, 1)[0];

    // Delete the file from Firebase storage
    if (attachmentToDelete) {
      try {
        const storageRef = ref(
          storage,
          `${homeworkDetails.teacher_uuid}/${homeworkDetails.class_uuid}/${homeworkDetails.homework_uuid}/attachments/${attachmentToDelete.name}`
        );
        await deleteObject(storageRef);
      } catch (error) {
        console.error("Error deleting file from storage:", error);
      }
    }

    setHomeworkDetails({ ...homeworkDetails, attachments: newAttachments });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    // Title validation
    if (
      !homeworkDetails.homework_title ||
      homeworkDetails.homework_title.length > 50
    ) {
      newErrors.title = "Title is required and should be maximum 50 characters";
      valid = false;
    } else {
      newErrors.title = "";
    }

    // Start Date validation
    if (!homeworkDetails.homework_start_date) {
      newErrors.startDate = "Start Date is required";
      valid = false;
    }
    else if(
      classObject.start_date &&
      new Date(homeworkDetails.homework_start_date).setHours(0, 0, 0, 0) <
      new Date(classObject.start_date).setHours(0, 0, 0, 0))
      {
        newErrors.startDate = "Start Date cannot be before Class Start Date";
        valid = false;
      } else {
        newErrors.startDate = "";
      }

    // End Date validation
    if (!homeworkDetails.homework_end_date) {
      newErrors.endDate = "End Date is required";
      valid = false;
    } else if (
      new Date(homeworkDetails.homework_end_date).setHours(0, 0, 0, 0) <
      new Date(homeworkDetails.homework_start_date).setHours(0, 0, 0, 0)
    ) {
      newErrors.endDate = "End Date must be after Start Date";
      valid = false;
    } else {
      newErrors.endDate = "";
    }

    // Total Grade validation
    if (!homeworkDetails.total_grade) {
      newErrors.totalGrade = "Total Grade is required";
      valid = false;
    } else {
      newErrors.totalGrade = "";
    }

    setErrors(newErrors);
    return valid;
  };

  const clearFields = () => {
    setHomeworkDetails({
      homework_uuid: "",
      homework_title: "",
      homework_description: "",
      homework_start_date: "",
      homework_end_date: "",
      total_grade: "",
      attachments: [],
    });

    setErrors({
      title: "",
      startDate: "",
      endDate: "",
      totalGrade: "",
    });
    setUploadProgress({});
  };

  const handleAddHomeworkSubmit = async () => {
    setLoading(true);
    if (validateForm()) {
      try {
        const data = { ...homeworkDetails };
  
        // Filter new attachments for upload
        const newAttachments = data.attachments.filter(attachment => attachment.isNew);
  
        // Upload new attachments and get their URLs
        await uploadAttachments(newAttachments);
  
        // Now save the homework details with updated attachments
        data.attachments = data.attachments.map(({ file, isNew, ...rest }) => rest);
        data.homework_start_date = format(new Date(data.homework_start_date), "yyyy-MM-dd");
        data.homework_end_date = format(new Date(data.homework_end_date), "yyyy-MM-dd");
        await pushDb(data);
  
        handleClose();
      } catch (error) {
        console.error("Error adding homework:", error);
      }
    }
    setLoading(false);
  };

  const pushDb = async (data) => {
    const currentDate = new Date().toISOString().split("T")[0];
    const classRef = doc(db, "classes", data.class_uuid);
    const classData = await getDoc(classRef);
    const students = {};
    const student_uuids = [];

    for (const student of classData.data().student_uuids) {
      const studentData = await getDoc(doc(db, "users", student));
      if (studentData.exists()) {
        students[student] = {
          student_uuid: student,
          homework_status: "Pending",
          homework_grade: "Pending",
          name:
            studentData.data().firstname + " " + studentData.data().lastname,
        };
        student_uuids.push(student);
      } else {
        console.error(`Student with UUID ${student} does not exist.`);
      }
    }

    await setDoc(doc(db, "homeworks", data.homework_uuid), {
      class_uuid: data.class_uuid,
      teacher_uuid: data.teacher_uuid,
      homework_uuid: data.homework_uuid,
      homework_title: data.homework_title,
      homework_description: data.homework_description,
      homework_start_date: data.homework_start_date,
      homework_end_date: data.homework_end_date,
      total_grade: data.total_grade,
      attachments: data.attachments,
      homework_status: data.homework_status,
      homework_created_date: currentDate,
      student_uuids: student_uuids,
      students: students,
    });

    // Check if homework_uuid is already in the homeworks array
    const existingHomeworks = classData.data().homeworks || [];
    if (!existingHomeworks.includes(data.homework_uuid)) {
      const newHomeworkIds = [...existingHomeworks, data.homework_uuid];
      await updateDoc(classRef, { homeworks: newHomeworkIds });
    }
  };

  const handleClose = () => {
    clearFields();
    onClose();
  };

  const isHomeworkCompleted = homeworkDetails.homework_status === "Completed";

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Homework</DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Title"
          type="text"
          name="homework_title"
          fullWidth
          value={homeworkDetails.homework_title}
          onChange={handleInputChange}
          error={Boolean(errors.title)}
          helperText={errors.title}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          type="text"
          name="homework_description"
          multiline
          rows={3}
          fullWidth
          value={homeworkDetails.homework_description}
          onChange={handleInputChange}
        />
        <Grid item xs={6} style={{ height: ".5em" }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                margin="dense"
                id="start_date"
                label="Start Date"
                type="date"
                name="homework_start_date"
                disabled={isHomeworkCompleted}
                fullWidth
                value={homeworkDetails.homework_start_date}
                onChange={(date) => handleDateChange("homework_start_date", date)}
                InputLabelProps={{ shrink: true }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    error: Boolean(errors.startDate),
                    helperText: errors.startDate,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                margin="dense"
                id="end_date"
                label="End Date"
                type="date"
                name="homework_end_date"
                disabled={isHomeworkCompleted}
                fullWidth
                value={homeworkDetails.homework_end_date}
                onChange={(date) => handleDateChange("homework_end_date", date)}
                InputLabelProps={{ shrink: true }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    error: Boolean(errors.endDate),
                    helperText: errors.endDate,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ height: ".5em" }} />
        <TextField
          margin="dense"
          id="total_grade"
          label="Total Grade"
          type="number"
          name="total_grade"
          fullWidth
          value={homeworkDetails.total_grade}
          onChange={handleInputChange}
          error={Boolean(errors.totalGrade)}
          helperText={errors.totalGrade}
        />
        <Grid container spacing={1} alignItems="center">
        {homeworkDetails.attachments
          .filter((attachment) => !uploadProgress[attachment.name])
          .map((attachment, index) => (
            <React.Fragment key={index}>
              <Grid item xs={10}>
                <Typography noWrap style={{ marginRight: 'auto' }}>
                  {attachment.name}
                </Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <IconButton onClick={() => deleteAttachmentField(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        <Grid item xs={6} style={{ height: '.5em' }} />
        {Object.keys(uploadProgress).map((fileName) => (
          <Grid item xs={12} key={fileName}>
            <Typography>{fileName}</Typography>
            <Typography>{Math.round(uploadProgress[fileName])}%</Typography>
            <LinearProgress variant="determinate" value={uploadProgress[fileName]} />
          </Grid>
        ))}

        <Grid item xs={12}>
          <input
            type="file"
            onChange={handleAttachmentChange}
            style={{ display: "none" }}
            id="file-input"
            multiple
          />
          <label htmlFor="file-input">
            <Button
              variant="contained"
              component="span"
              startIcon={<AddIcon />}
            >
              Add File 
            </Button>
          </label>
        </Grid>
      </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          sx={{ width: "70px" }}
          onClick={handleAddHomeworkSubmit}
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HomeworkEdit;
