import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";
import image from "../assets/images/teacher-dashboard.jpg";
import EnrollmentPopup from "./enrollment_popup";
import ClassAssistantPopup from "./class_assistant";
import { useNavigate } from "react-router-dom";
import ClassEdit from "./class_edit";
import ClassCopy from "./class_copy";
import StudentListPopup from "./student_details_popup";
import PopUp from "./popup";
import ClassView from "./class_view";

const ClassCard = ({ role, classObj, handleDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [openClassDetails, setOpenClassDetails] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAssistantPopupOpen, setIsAssistantPopupOpen] = useState(false);

  const navigate = useNavigate();

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    handleClose();
  };

  const openAssistantPopup = () => {
    setIsAssistantPopupOpen(true);
  };

  const closeAssistantPopup = () => {
    setIsAssistantPopupOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onDelete = () => {
    handleDelete(classObj.class_uuid);
  };

  const handleHomeworkClick = () => {
    if (role === "teacher") {
      navigate("/teacherHomeworkDashboard", {
        state: { class_uuid: classObj.class_uuid, class_obj: classObj },
      });
    } else {
      navigate("/studentHomeworkDashboard", {
        state: { class_uuid: classObj.class_uuid },
      });
    }
  };

  const handleClassDetials = () => {
    if (role === "student") {
      setOpenClassDetails(true);
    }
  };

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCopyClass = () => {
    setOpenCopy(true);
  };

  const handleCloseCopy = () => {
    setOpenCopy(false);
  };

  const truncate = (text, length) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + "..";
  };

  return (
    <>
      <Card sx={{ maxWidth: 400, height: 170 }}>
        <CardMedia
          component="img"
          height="20px"
          image={image}
          alt={classObj.class_title}
        />
        <CardHeader
          height="150px"
          action={
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Tooltip title={classObj.class_title}>
              <span style={{ fontSize: "20px" }}>
                {truncate(classObj.class_title, 20)}
              </span>
            </Tooltip>
          }
          subheader={
            <Tooltip title={classObj.class_description}>
              <span style={{ fontSize: "15px" }}>
                {truncate(classObj.class_description, 20)}
              </span>
            </Tooltip>
          }
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Fee Details</MenuItem>
          <MenuItem onClick={handleClose}>Extra Class Details</MenuItem>
          <MenuItem onClick={handleHomeworkClick}>Homework Details</MenuItem>
          {role === "student" && (
            <MenuItem onClick={handleClassDetials}>Class Details</MenuItem>
          )}
          {role === "teacher" && (
            <>
              <MenuItem onClick={openPopup}>Student Details</MenuItem>
              <Divider />
              <MenuItem onClick={handleOpenDialog}>Enroll Students</MenuItem>
              <MenuItem onClick={openAssistantPopup}>Class Assistant</MenuItem>
              <Divider />
              <MenuItem onClick={handleEditOpen}>Edit / View Class</MenuItem>
              <MenuItem onClick={handleCopyClass}>Copy Class</MenuItem>
              <MenuItem onClick={onDelete} style={{ color: "red" }}>
                Delete Class
              </MenuItem>
            </>
          )}
        </Menu>
      </Card>

      <EnrollmentPopup
        open={openDialog}
        handleClose={handleCloseDialog}
        classObj={classObj}
      />
      <ClassAssistantPopup
        open={isAssistantPopupOpen}
        handleClose={closeAssistantPopup}
        classObj={classObj}
      />
      <ClassEdit
        open={openEdit}
        handleCloseEdit={handleCloseEdit}
        class_uuid={classObj.class_uuid}
      />
      <ClassCopy
        open={openCopy}
        handleCloseCopy={handleCloseCopy}
        class_uuid={classObj.class_uuid}
        class_obj={classObj}
      />
      <StudentListPopup
        isOpen={isPopupOpen}
        onClose={closePopup}
        class_uuid={classObj.class_uuid}
      />

      {openClassDetails && (
        <PopUp
          maxWidth="sm"
          title="Class Details"
          setOpen={setOpenClassDetails}
        >
          <ClassView classObj={classObj} />
        </PopUp>
      )}
    </>
  );
};

export default ClassCard;
