import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const CustomDropdrown = ({ className, value, menuItems, label, onChange }) => {
  return (
    <FormControl style={{ padding: "0px" }}>
      <InputLabel
        style={{
          fontFamily: "Montserrat",
          fontSize: "17px",
          display: "flex",
          alignItems: "center",
          height: "12px",
        }}
      >
        {label}
      </InputLabel>
      <Select
        className={className}
        value={value}
        onChange={onChange}
        label={label}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomDropdrown;
