import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, IconButton, Stack } from "@mui/material";

export const PopUp = (props) => {
  const { setOpen, title, maxWidth, children } = props;

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog maxWidth={maxWidth} fullWidth disableEscapeKeyDown open={true}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box>{title}</Box>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default PopUp;
