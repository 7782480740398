import React from "react";
import "./emailVerify.css";
import { useLocation } from "react-router-dom";

function EmailVerify() {
  const location = useLocation();
  return (
    <div className="email-verify-container">
      <div className="email-verify-card">
        <div className="email-icon"></div>
        <p className="email-verify-text-1">
          We have sent a verification email to the following email.
        </p>
        <p className="email-verify-text-2">{location.state.email}</p>
        <p className="email-verify-text-3">
          Kindly verify the email address to continue.
        </p>
      </div>
    </div>
  );
}

export default EmailVerify;
