import React, { useState } from "react";
import "./register.css";
import GoogleSignInButton from "../../components/google_auth_button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Footer from "../../components/footer";

function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleRegistration = async () => {
    if (!validateEmail(username)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (password !== repassword) {
      alert("Passwords do not match.");
      return;
    }
    if (
      username !== null &&
      password !== null &&
      repassword != null &&
      password === repassword
    ) {
      const auth = getAuth();
      try {
        const response = await createUserWithEmailAndPassword(
          auth,
          username,
          password
        );
        const user = response.user;
        pushDb(user);
        await sendEmailVerification(auth.currentUser);
        navigate("/emailVerify", { state: { email: username } });
      } catch (err) {
        if (err.code === 'auth/email-already-in-use') {
          alert("Already registered, please login");
        } else {
          alert("An error occurred. Please try again");
        }
      }
    }
  };

  const pushDb = async (data) => {
    await setDoc(doc(db, "users", data.uid), {
      firstname: "",
      lastname: "",
      countryCode: "",
      mobile: "",
      email: data.email,
      status: "profile-pending",
      gender: "",
      role: "",
      uuid: data.uid,
      verified: data.emailVerified,
    });
  };

  return (
    <div className="register-container">
      <div className="register-card">
        <div className="register-form">
          <h1 className="register-logo">Embark</h1>
          <h2 className="register-title">Welcome! Please Login To Continue.</h2>
          <input
            className="register-username"
            placeholder="Email"
            type="text"
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="register-password"
            placeholder="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            className="re-password"
            placeholder="Retype Password"
            type="password"
            onChange={(e) => setRepassword(e.target.value)}
          />
          <button className="register-button" onClick={handleRegistration}>
            Register
          </button>
          <GoogleSignInButton />
          <p className="login-link">
            Already have an account?{" "}
            <Link className="login-link-button" to="/">
              Login!
            </Link>{" "}
          </p>
        </div>
        <div className="register-image"></div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;