import React, { useState, useEffect } from "react";
import "./studentHomeworkDashboard.css";
import { Grid, Typography, Button, Box } from "@mui/material";
import NavBar from "../../components/navbar";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { auth, db } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import HomeworkCard from "../../components/homework_card";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

function StudentHomeworkDashboard() {
  const [homeworks, setHomeworks] = useState([]);
  const [displayName, setDisplayName] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(true); // Set loading to true initially
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setDisplayName(user.email);
        fetchHomeworks(user.uid, location.state.class_uuid);
      } else {
        console.log("Session Expired");
      }
    });
    // eslint-disable-next-line
  }, []);

  const fetchHomeworks = async (uid, class_uuid) => {
    const q = query(
      collection(db, "homeworks"),
      where("class_uuid", "==", class_uuid),
      where("student_uuids", "array-contains", uid)
    );
    // Set up a listener to get real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const homeworksData = [];
      querySnapshot.forEach((doc) => {
        homeworksData.push(doc.data());
      });
      homeworksData.sort((a, b) => a.homework_title.localeCompare(b.homework_title));
      setHomeworks(homeworksData);
      setLoading(false); // Set loading to false when data is received
    });
    return unsubscribe;
  };

  return (
    <div className="shd-container">
      <div className="shd-navbar">
        <NavBar name={displayName} role={"Student"} />
      </div>
      <div className="arrowback">
          <Button
          tabIndex={-1}
          startIcon={<ArrowBackIcon />}
          className="arrowback-icon"
          style={{color:"black", padding:"24px"}}
          onClick={() => navigate("/studentDashboard")}
        >
          Student Dashboard
        </Button>
        </div>
      <div className="shd-homework-card">
        {loading ? (
          <CircularProgress style={{ color: "white", marginLeft: "50%" }} />
        ) : (
          homeworks.length === 0 ? (
            <Box textAlign="center" mt={5}>
              <Typography variant="h5" style={{ margin: "20px" }}>
                No homeworks yet...
              </Typography>
            </Box>
          ) : (
            <Grid style={{ marginLeft: "20px", marginRight: "20px" }} item xs={9}>
              <Grid container spacing={2}>
                {homeworks.map((hms) => (
                  <Grid key={hms.homework_uuid} item xs={12} sm={6} md={4} lg={3}>
                    <HomeworkCard role={"student"} homeworkObj={hms} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )
        )}
      </div>
    </div>
  );
}

export default StudentHomeworkDashboard;