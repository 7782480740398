import React, { useState } from "react";
import "./login.css";
import GoogleSignInButton from "../../components/google_auth_button";
import { Link } from "react-router-dom";
import { db } from "../../firebase/firebase";
import {
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import Footer from "../../components/footer";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    console.log("Release version : v1.0.2")
    const auth = getAuth();
    try {
      const response = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );
      const user = response.user;
      if (user.emailVerified) {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        if (data.status === "profile-pending") {
          navigate("/profile");
        } else {
          if (data.role === "teacher") {
            navigate("/teacherDashboard");
          } else {
            navigate("/studentDashboard");
          }
        }
      } else {
        sendEmailVerification(auth.currentUser);
        navigate("/emailVerify");
      }
    } catch (err) {
      console.log(err);
      alert(
        "Invalid Credentials.. Register your account if you don't have one!"
      );
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-form">
          <h1 className="login-logo">Embark</h1>
          <h2 className="login-title">Welcome! Please Login To Continue.</h2>
          <input
            className="username"
            placeholder="Email ...."
            type="text"
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="password"
            placeholder="Password ...."
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Link to="/register" className="forgot-password">
            Forgot Password?
          </Link>
          <button className="login-button" onClick={handleLogin}>
            Login
          </button>
          <GoogleSignInButton />
          <p className="sign-up-link">
            Don't have an account?{" "}
            <Link className="register-link" to="/register">
              Sign Up!
            </Link>{" "}
          </p>
        </div>
        <div className="login-image"></div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;