import React, { useState, useEffect } from "react";
import "./studentDashboard.css";
import { Grid, Typography, Box } from "@mui/material";
import NavBar from "../../components/navbar";
import ClassCard from "../../components/class_card";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { auth, db } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import CircularProgress from "@mui/material/CircularProgress";

function StudentDashboard() {
  const [classes, setClasses] = useState([]);
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setDisplayName(user.email);
        console.log(user.uid);
        fetchClasses(user.uid);
      } else {
        console.log("Session Expired");
      }
    });
    // eslint-disable-next-line
  }, []);

  const fetchClasses = async (uid) => {
    const q = query(
      collection(db, "classes"),
      where("student_uuids", "array-contains", uid)
    );
    // Set up a listener to get real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const classesData = [];
      querySnapshot.forEach((doc) => {
        classesData.push(doc.data());
      });
      classesData.sort((a, b) => a.class_title.localeCompare(b.class_title));
      setClasses(classesData);
      setLoading(false);
    });
    return unsubscribe;
  };

  return (
    <div className="sd-container">
      <div className="sd-navbar">
        <NavBar name={displayName} role={"Student"} />
      </div>
      <div className="sd-class-card">
      {loading ? (
          <CircularProgress style={{ color: "white", marginLeft: "50%" }} />
        ) 
        : (
        classes.length === 0 ? (
          <Box textAlign="center" mt={5}>
            <Typography variant="h5" style={{ margin: "20px" }}>
              No classes yet...
            </Typography>
          </Box>
        ) : (
          <Grid style={{ marginLeft: "20px", marginRight: "20px" }} item xs={9}>
            <Grid container spacing={2}>
              {classes.map((cls) => (
                <Grid key={cls.class_uuid} item xs={12} sm={6} md={4} lg={3}>
                  <ClassCard role={"student"} classObj={cls} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )
        )}
      </div>
    </div>
  );
}

export default StudentDashboard;
