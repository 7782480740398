import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import image1 from "../assets/images/teacher-dashboard.jpg";
import HomeworkSubmitPopup from "./homework_submit_popup";
import HomeworkEvaluatePopup from "./homework_evaluate_popup";
import { auth, db } from "../firebase/firebase";
import { Timestamp } from "firebase/firestore";
import HomeworkEdit from "./homework_edit";
import HomeworkCopy from "./homework_copy";
import { doc, updateDoc } from "firebase/firestore";

function formatDate(timestamp) {
  if (!timestamp) return "Invalid Date";
  const date = timestamp instanceof Timestamp ? timestamp.toDate() : new Date(timestamp);
  if (isNaN(date)) return "Invalid Date";
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

const HomeworkCard = ({ 
  role, 
  homeworkObj, 
  handleDelete, 
  classObject 
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openStudentDialog, setOpenStudentDialog] = useState(false);
  const [openTeacherDialog, setOpenTeacherDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseStudentDialog = () => {
    setOpenStudentDialog(false);
  };

  const handleCloseTeacherDialog = () => {
    setOpenTeacherDialog(false);
  };

  const onDelete = () => {
    console.log(homeworkObj.homework_uuid); // Make sure homework_uuid is used here
    handleDelete(homeworkObj.homework_uuid);
  };

  const EndHomework = () => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to end this homework?"
      );
      if (confirmed) {
        const homeworkRef = doc(db, "homeworks", homeworkObj.homework_uuid); // Construct document reference
        updateDoc(homeworkRef, { homework_status: "Completed" }); // Update the document directly
        console.log("Homework ended successfully.");
      } else {
        console.log("Ending homework cancelled.");
      }
    } catch (error) {
      console.error("Error ending homework:", error);
    }
  };

  const handleCardClick = () => {
    if (role === "student") {
      setOpenStudentDialog(true);
    } else {
      setOpenTeacherDialog(true);
    }
  };

  const getStudentStatusColor = () => {
    switch (homeworkObj.students[auth.currentUser.uid].homework_status) {
      case "Pending":
        return "orange";
      case "Submitted":
        return "green";
      case "Graded":
        return "green";
      case "Missed":
        return "red";
      default:
        return "default";
    }
  };

  const getTeacherStatusColor = () => {
    switch (homeworkObj.homework_status) {
      case "Ongoing":
        return "orange";
      case "Completed":
        return "green";
      case "Closed":
        return "red";
      default:
        return "default";
    }
  };

  const getSubmittedCount = () => {
    let count = 0;
    for (const studentId in homeworkObj.students) {
      const student = homeworkObj.students[studentId];
      if (student.homework_status === 'Submitted' || student.homework_status !== 'Pending') {
        count++;
      }
    }
    return count;
  };

  const getGradedCount = () => {
    let count = 0;
    for (const studentId in homeworkObj.students) {
      const student = homeworkObj.students[studentId];
      if (student.homework_status === 'Graded') {
        count++;
      }
    }
    return count;
  };

  const handleEdit = () => {
    setOpenEdit(true);
    setOpenTeacherDialog(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCopy = () => {
    setOpenCopy(true);
  };

  const handleCloseCopy = () => {
    setOpenCopy(false);
  };

  const truncate = (text, length) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '..';
  };

  return (
    <>
      <Card
        onClick={handleCardClick}
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        <CardMedia
          component="img"
          height="20px"
          image={image1}
          alt={homeworkObj.homework_title}
        />
        <CardHeader
          title={
              <span
                style={{
                  fontSize: isMobile ? "12px" : isTablet ? "14px" : "16px",
                }}
              >
                {homeworkObj.homework_title}
              </span>
          }
          subheader={
            <>
              <Tooltip title={homeworkObj.homework_description}>
                <span
                  style={{
                    fontSize: isMobile ? "10px" : isTablet ? "12px" : "14px",
                    display: "block",
                    whiteSpace: "nowrap",
                    maxWidth: "250px",
                  }}
                >
                  {truncate(homeworkObj.homework_description, 30)}
                </span>
              </Tooltip>
              <Chip
                label={
                  role === "student"
                    ? homeworkObj.students[auth.currentUser.uid].homework_status
                    : homeworkObj.homework_status
                }
                style={{
                  marginTop: "5px",
                  fontWeight: "600",
                  color: "white",
                  backgroundColor: `${
                    role === "student"
                      ? getStudentStatusColor()
                      : getTeacherStatusColor()
                  }`,
                  fontSize: isMobile ? "10px" : isTablet ? "12px" : "14px",
                }}
              />
            </>
          }
          action={
            role === "teacher" && (
              <IconButton
                onClick={(e) => {
                  handleClick(e);
                  e.stopPropagation();
                }}
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
        />

        <CardContent sx={{ flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {role === "teacher" && (
            <Typography
              variant="body1"
              color="text.secondary"
              style={{ marginTop: "0px" }}
            >
              {homeworkObj.homework_start_date && (
                `Start Date: ${formatDate(homeworkObj.homework_start_date)}`
                )}
            </Typography>
          )}
          <Typography variant="body1" color="text.secondary">
            {homeworkObj.homework_end_date && (
              `End Date: ${formatDate(homeworkObj.homework_end_date)}`
            )}
          </Typography>
          {role === "student" && (
            <Typography
              variant="body1"
              color="text.secondary"
              style={{ marginTop: "0px" }}
            >
              Grade: {homeworkObj.students[auth.currentUser.uid]?.homework_grade} / {homeworkObj.total_grade}
            </Typography>
          )}

          {role === "teacher" && (
            <Typography
              variant="body1"
              color="text.secondary"
              style={{ marginTop: "0px" }}
            >
              Submitted: {getSubmittedCount()} of{" "}
              {homeworkObj.student_uuids.length}
            </Typography>
          )}
          {role === "teacher" && (
            <Typography
              variant="body1"
              color="text.secondary"
            >
              Graded: {getGradedCount()} of{" "} 
              {homeworkObj.student_uuids.length}
            </Typography>
          )}
        </CardContent>

        {role === "teacher" && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={(e) => {
              handleClose();
              e.stopPropagation();
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            getcontentanchorel={null}
          >
            <MenuItem
              onClick={(e) => {
                handleEdit();
                e.stopPropagation();
              }}
            >
              Edit Homework
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleCopy();
                e.stopPropagation();
              }}
            >
              Copy Homework
            </MenuItem>
            {new Date() > new Date(homeworkObj.homework_end_date) &&
              homeworkObj.homework_status !== "Completed" && (
                <MenuItem
                  onClick={(e) => {
                    EndHomework();
                    e.stopPropagation();
                  }}
                >
                  End Homework
                </MenuItem>
              )}
            <MenuItem
              onClick={(e) => {
                onDelete();
                e.stopPropagation();
              }}
              style={{ color: "red" }}
            >
              Delete Homework
            </MenuItem>
          </Menu>
        )}
      </Card>
      {role === "teacher" && (
        <HomeworkCopy
          isOpen={openCopy}
          onClose={handleCloseCopy}
          homeworkObj={homeworkObj}
          classObject={classObject}
        />
      )}
      {role === "teacher" && openEdit && (
        <HomeworkEdit
          isOpen={openEdit}
          onClose={handleCloseEdit}
          homeworkObj={homeworkObj}
          classObject={classObject}
        />
      )}
      {role === "student" && (
        <HomeworkSubmitPopup
          isOpen={openStudentDialog}
          onClose={handleCloseStudentDialog}
          homeworkObj={homeworkObj}
        />
      )}
      {role === "teacher" && (
        <HomeworkEvaluatePopup
          isOpen={openTeacherDialog}
          onClose={handleCloseTeacherDialog}
          onSave={handleClick}
          homeworkObj={homeworkObj}
        />
      )}
    </>
  );
};

export default HomeworkCard;