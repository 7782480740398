import { getAdditionalUserInfo } from "firebase/auth";
import { signInWithGooglePopup } from "../firebase/firebase.js";
import GoogleButton from "react-google-button";
import { useNavigate } from "react-router-dom";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { db, auth } from "../firebase/firebase";

const GoogleSignInButton = () => {
  const pushDb = async (data) => {
    await setDoc(doc(db, "users", data.uid), {
      firstname: "",
      lastname: "",
      countryCode: "",
      mobile: "",
      email: data.email,
      status: "profile-pending",
      gender: "",
      role: "",
      uuid: data.uid,
      verified: data.emailVerified,
    });
  };

  const navigate = useNavigate();
  const authenticateGoogle = async () => {
    const response = await signInWithGooglePopup();
    const user = response.user;
    const additionUserInfo = getAdditionalUserInfo(response);
    if (additionUserInfo.isNewUser) {
      pushDb(user);
      navigate("/profile");
    } else {
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      if (data.status === "profile-pending") {
        navigate("/profile");
      } else {
        if (data.role === "teacher") {
          navigate("/teacherDashboard");
        } else {
          navigate("/studentDashboard");
        }
      }
    }
  };
  return (
    <div>
      <GoogleButton
        type="light"
        id="google-button"
        label="Sign In With Google"
        onClick={authenticateGoogle}
      />
    </div>
  );
};
export default GoogleSignInButton;
