import React from 'react';
import { Box, Typography } from '@mui/material';
import packageJson from '../../package.json';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const version = "V" + packageJson.version;

  return (
    <Box
      component="footer"
      sx={{
        textAlign: 'center',
        padding: '16px 0',
        backgroundColor: '#f5f5f5',
        width: '100%',
        position: 'absolute',
        bottom: 0,
      }}
    >
      <Typography variant="body2" color="text.secondary">
        © {currentYear}, Walnut Infotech, Inc. All Rights Reserved. | {version}
      </Typography>
    </Box>
  );
};

export default Footer;
