import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./layouts/login/login";
import Profile from "./layouts/profile/profile";
import Register from "./layouts/register/register";
import TeacherDashboard from "./layouts/teacherDashboard/teacherDashboard";
import EmailVerify from "./layouts/emailVerify/emailVerify";
import StudentDashboard from "./layouts/studentDashboard/studentDashboard";
import TeacherHomeworkDashboard from "./layouts/teacherHomeworkDashboard/teacherHomeworkDashboard";
import StudentHomeworkDashboard from "./layouts/studentHomeworkDashboard/studentHomeworkDashboard";
import Tools from "./layouts/tools";
import StudentTable from "./layouts/analyticsDashboard/analyticsDashboard";
import { ThemeProvider } from "@mui/material";
import { theme } from "./utils/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/teacherDashboard" element={<TeacherDashboard />} />
        <Route path="/emailVerify" element={<EmailVerify />} />
        <Route path="/studentDashboard" element={<StudentDashboard />} />
        <Route
          path="/teacherHomeworkDashboard"
          element={<TeacherHomeworkDashboard />}
        />
        <Route
          path="/studentHomeworkDashboard"
          element={<StudentHomeworkDashboard />}
        />
        <Route path="/tools" element={<Tools />} />
        <Route path="/analyticsDashboard" element={<StudentTable />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
