import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import {
  ref,
  getStorage,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function HomeworkPopup({ isOpen, onClose, onSubmit, classObj, loading, setLoading, homeworkUUID }) {
  const currentDate = new Date().toISOString().split("T")[0];
  const [homeworkDetails, setHomeworkDetails] = useState({
    title: "",
    description: "",
    startDate: new Date(),
    endDate: (() => {
      const today = new Date();
      today.setMonth(today.getMonth() + 3);
      return today;
    })(),
    totalGrade: "100",
    attachments: [],
  });
  const [errors, setErrors] = useState({
    title: "",
    startDate: "",
    endDate: "",
    totalGrade: "",
  });

  const [uploadProgress, setUploadProgress] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHomeworkDetails({ ...homeworkDetails, [name]: value });
  };

  const handleDateChange = (name, date) => {
    setHomeworkDetails({ ...homeworkDetails, [name]: date });
  };

  const handleAttachmentChange = (event) => {
    const files = Array.from(event.target.files);
    const validTypes = ["audio/", "video/"];

    const invalidFiles = files.filter(
      (file) => !validTypes.some((type) => file.type.startsWith(type))
    );

    if (invalidFiles.length > 0) {
      alert("You can only upload audio or video files.");
      return;
    }

    const validFiles = files.filter((file) =>
      validTypes.some((type) => file.type.startsWith(type))
    );

    const newAttachments = validFiles.map((file) => ({
      name: file.name,
      file: file,
      type: file.type,
      isNew: true,
    }));

    uploadAttachments(newAttachments);
  };

  const uploadAttachments = (attachments) => {
    const storage = getStorage();

    attachments.forEach((file) => {
      const storageRef = ref(
        storage,
        `${classObj.teacher_uuid}/${classObj.class_uuid}/${homeworkUUID}/attachments/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file.file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: progress,
          }));
        },
        (error) => {
          console.error("Upload failed:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setHomeworkDetails((prevDetails) => ({
              ...prevDetails,
              attachments: [
                ...prevDetails.attachments,
                { ...file, url: downloadURL },
              ],
            }));
            setUploadProgress((prevProgress) => {
              const { [file.name]: _, ...rest } = prevProgress;
              return rest;
            });
          });
        }
      );
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    // Title validation
    if (!homeworkDetails.title || homeworkDetails.title.length > 50) {
      newErrors.title = "Title is required and should be maximum 50 characters";
      valid = false;
    } else {
      newErrors.title = "";
    }

    // Start Date validation
    if (!homeworkDetails.startDate) {
      newErrors.startDate = "Start Date is required";
      valid = false;
    } else if (
      classObj.start_date &&
      new Date(homeworkDetails.startDate).setHours(0, 0, 0, 0) <
      new Date(classObj.start_date).setHours(0, 0, 0, 0)
    ) {
      newErrors.startDate = "Start Date cannot be before Class Start Date";
      valid = false;
    } else {
      newErrors.startDate = "";
    }

    // End Date validation
    if (!homeworkDetails.endDate) {
      newErrors.endDate = "End Date is required";
      valid = false;
    } else if (
      new Date(homeworkDetails.endDate).setHours(0, 0, 0, 0) <
      new Date(homeworkDetails.startDate).setHours(0, 0, 0, 0)
    ) {
      newErrors.endDate = "End Date must be after Start Date";
      valid = false;
    } else {
      newErrors.endDate = "";
    }

    // Total Grade validation
    if (!homeworkDetails.totalGrade) {
      newErrors.totalGrade = "Total Grade is required";
      valid = false;
    } else {
      newErrors.totalGrade = "";
    }

    setErrors(newErrors);
    return valid;
  };

  const deleteAttachmentField = (index) => {
    const updatedAttachments = [...homeworkDetails.attachments];
    updatedAttachments.splice(index, 1);
    setHomeworkDetails({ ...homeworkDetails, attachments: updatedAttachments });
  };

  const handleAddHomeworkSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      onSubmit(homeworkDetails)
        .then(() => {
          clearFields();
          onClose();
        })
        .finally(() => setLoading(false));
    }
  };  

  const clearFields = () => {
    setHomeworkDetails({
      title: "",
      description: "",
      startDate: new Date(),
      endDate: (() => {
        const today = new Date();
        today.setMonth(today.getMonth() + 3);
        return today;
      })(),
      totalGrade: "100",
      attachments: [],
    });

    setErrors({
      title: "",
      startDate: "",
      endDate: "",
      totalGrade: "",
    });

    setUploadProgress({});
  };

  const handleClose = () => {
    clearFields();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Homework</DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          type="text"
          name="title"
          fullWidth
          value={homeworkDetails.title}
          onChange={handleInputChange}
          error={Boolean(errors.title)}
          helperText={errors.title}
        />
        <TextField
          margin="dense"
          label="Description"
          type="text"
          name="description"
          multiline
          rows={3}
          fullWidth
          value={homeworkDetails.description}
          onChange={handleInputChange}
        />
        <Grid item xs={6} style={{ height: ".5em" }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={
                  homeworkDetails.startDate
                    ? new Date(homeworkDetails.startDate)
                    : null
                }
                label="Start Date"
                onChange={(date) => handleDateChange("startDate", date)}
                inputFormat="MM/dd/yyyy"
                InputLabelProps={{ shrink: true }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    name: "startDate",
                    error: Boolean(errors.startDate),
                    helperText: errors.startDate,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={
                  homeworkDetails.endDate
                    ? new Date(homeworkDetails.endDate)
                    : null
                }
                label="End Date"
                onChange={(date) => handleDateChange("endDate", date)}
                inputFormat="MM/dd/yyyy"
                InputLabelProps={{ shrink: true }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    name: "endDate",
                    error: Boolean(errors.endDate),
                    helperText: errors.endDate,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ height: ".5em" }} />
        <TextField
          margin="dense"
          label="Total Grade"
          type="number"
          name="totalGrade"
          fullWidth
          value={homeworkDetails.totalGrade}
          onChange={handleInputChange}
          error={Boolean(errors.totalGrade)}
          helperText={errors.totalGrade}
        />
        <Grid container spacing={1} alignItems="center">
          {homeworkDetails.attachments.map((file, index) => (
            <React.Fragment key={index}>
              <Grid item xs={10}>
                <Typography noWrap style={{ marginRight: "auto" }}>
                  {file.name}
                </Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <IconButton
                  style={{ marginLeft: "5px" }}
                  onClick={() => deleteAttachmentField(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={6} style={{ height: ".5em" }} />
          {Object.keys(uploadProgress).map((fileName) => (
            <Grid item xs={12} key={fileName}>
              <Typography>{fileName}</Typography>
              <Typography>{Math.round(uploadProgress[fileName])}%</Typography>
              <LinearProgress variant="determinate" value={uploadProgress[fileName]} />
            </Grid>
          ))}
        </Grid>
        <input
          accept="audio/*,video/*"
          id="file-input"
          multiple
          type="file"
          style={{ display: "none" }}
          onChange={handleAttachmentChange}
        />
        <label htmlFor="file-input">
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={<AddIcon />}
            style={{ marginTop: "10px" }}
          >
            Add Attachments
          </Button>
        </label>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleAddHomeworkSubmit}
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HomeworkPopup;
