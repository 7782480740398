import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DescriptionIcon from "@mui/icons-material/Description";
import PaidIcon from "@mui/icons-material/Paid";
import PaymentIcon from "@mui/icons-material/Payment";
import TitleIcon from "@mui/icons-material/Title";
import moment from "moment";

const formatDate = (date) => {
  return moment(date).format("MMMM DD, YYYY");
};

const formatTime = (time) => {
  return moment(time, "HH:mm").format("hh:mm A");
};

const formatFees = (currency, fees) => {
  switch (currency) {
    case "USD":
      return `$${fees}`;
    case "INR":
      return `₹${fees}`;
    default:
      return `$${fees}`;
  }
};

const parseDescription = (description) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return description.split(urlRegex).map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#1e88e5" }}
        >
          {part}
        </a>
      );
    }
    return part;
  });
};

const ClassView = ({ classObj }) => {
  return (
    <Box p={0}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TitleIcon sx={{ mr: 1 }} />
            <Typography variant="body2" fontWeight="bold">
              Class Title
            </Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {classObj.class_title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <DescriptionIcon sx={{ mr: 1 }} />
            <Typography variant="body2" fontWeight="bold">
              Class Description
            </Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {parseDescription(classObj.class_description)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <CalendarTodayIcon sx={{ mr: 1 }} />
            <Typography variant="body2" fontWeight="bold">
              Start Date
            </Typography>
          </Box>
          <Typography variant="body1">
            {formatDate(classObj.start_date)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <CalendarTodayIcon sx={{ mr: 1 }} />
            <Typography variant="body2" fontWeight="bold">
              End Date
            </Typography>
          </Box>
          <Typography variant="body1">
            {formatDate(classObj.end_date)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <AccessTimeIcon sx={{ mr: 1 }} />
            <Typography variant="body2" fontWeight="bold">
              Start Time
            </Typography>
          </Box>
          <Typography variant="body1">
            {formatTime(classObj.start_time)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <AccessTimeIcon sx={{ mr: 1 }} />
            <Typography variant="body2" fontWeight="bold">
              End Time
            </Typography>
          </Box>
          <Typography variant="body1">
            {formatTime(classObj.end_time)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <PaidIcon sx={{ mr: 1 }} />
            <Typography variant="body2" fontWeight="bold">
              Fee
            </Typography>
          </Box>
          <Typography variant="body1">
            {formatFees(classObj.currency, classObj.fees)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <PaymentIcon sx={{ mr: 1 }} />
            <Typography variant="body2" fontWeight="bold">
              Fee Schedule
            </Typography>
          </Box>
          <Typography variant="body1">
            {classObj.fee_schedule} Payment
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClassView;
