import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Delete";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase/firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

function StudentListPopup({ isOpen, onClose, class_uuid }) {
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchDetails();
      } else {
        console.log("Session Expired");
      }
    });
    // eslint-disable-next-line
  }, []);

  const fetchDetails = () => {
    const q = query(
      collection(db, "classes"),
      where("class_uuid", "==", class_uuid)
    );
    // Set up a listener to get real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const classData = doc.data();
        if (classData && classData.students) {
          // Populate the students array from the students field in the document data
          const fetchedStudents = Object.keys(classData.students).map(
            (studentId) => ({
              id: studentId,
              name: classData.students[studentId].name,
              status: classData.students[studentId].status,
            })
          );
          setStudents(fetchedStudents);
        } else {
          console.error("No students found in the homework document");
        }
      });
    });
    return unsubscribe;
  };

  const [students, setStudents] = useState([]);

  const deleteStudent = async (studentId) => {
    try {
      // Show a confirmation dialog
      const confirmed = window.confirm(
        "Are you sure you want to delete this student from the class?"
      );

      if (confirmed) {
        // Step 1: Find all the homeworks associated with the class
        const homeworkQuery = query(
          collection(db, "homeworks"),
          where("class_uuid", "==", class_uuid)
        );
        const homeworkSnapshot = await getDocs(homeworkQuery);

        // Step 2: Delete the student ID from all the homeworks
        homeworkSnapshot.forEach(async (doc) => {
          const homeworkData = doc.data();
          if (
            homeworkData &&
            homeworkData.students[studentId] &&
            homeworkData.student_uuids.includes(studentId)
          ) {
            const updatedStudentUuids = homeworkData.student_uuids.filter(
              (id) => id !== studentId
            );
            const updatedStudents = { ...homeworkData.students };
            delete updatedStudents[studentId];
            await updateDoc(doc.ref, {
              student_uuids: updatedStudentUuids,
              students: updatedStudents,
            });
          }
        });

        // Step 3: Delete the student ID from the class object
        const classRef = doc(db, "classes", class_uuid);
        const classDoc = await getDoc(classRef);
        if (classDoc.exists()) {
          const classData = classDoc.data();
          if (
            classData &&
            classData.students[studentId] &&
            classData.student_uuids.includes(studentId)
          ) {
            const updatedStudentUuids = classData.student_uuids.filter(
              (id) => id !== studentId
            );
            const updatedStudents = { ...classData.students };
            delete updatedStudents[studentId];
            await updateDoc(classRef, {
              student_uuids: updatedStudentUuids,
              students: updatedStudents,
            });
          }
        } else {
          console.error("Class document does not exist");
        }

        console.log("Student deleted successfully.");
      } else {
        // User cancelled the deletion
        console.log("Deletion cancelled.");
      }
    } catch (error) {
      console.error("Error deleting student:", error);
    }
  };

  const cancelStudent = (studentName) => {
    // Implement cancel logic here
    console.log(`Canceling invitation for student: ${studentName}`);
  };

  const truncate = (text, length) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Student List</DialogTitle>
      <DialogContent>
        {students.length > 0 && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Tooltip title={student.name}>
                        <span>{truncate(student.name, 20)}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={student.status}
                      style={{
                        fontWeight: "700",
                        color:
                          student.status === "Enrolled" ? "green" : "orange",
                      }}
                    >
                      {student.status}
                    </TableCell>
                    <TableCell>
                      {student.status === "Enrolled" ? (
                        <IconButton onClick={() => deleteStudent(student.id)}>
                          <CloseIcon />
                        </IconButton>
                      ) : student.status === "Pending" ? (
                        <>
                          <IconButton
                            onClick={() => cancelStudent(student.name)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {students.length === 0 && (
          <Typography textAlign="center">No students enrolled yet</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default StudentListPopup;