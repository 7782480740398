import React, { useState, useEffect } from "react";
import "./teacherDashboard.css";
import { Grid, Button, Box, Typography, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NavBar from "../../components/navbar";
import ClassCard from "../../components/class_card";
import { ref, deleteObject, listAll } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import ClassPopup from "../../components/class_popup";
//import CircularProgress from "@mui/material/CircularProgress";

import {
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { format } from 'date-fns';

function TeacherDashboard() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [classes, setClasses] = useState([]);
  const [teacherUid, setTeacherUid] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false); // For form submission
  const [loadingClass, setLoadingClass] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setDisplayName(user.displayName);
        console.log(user.displayName);
        setTeacherUid(user.uid);
        fetchClasses(user.uid);
      } else {
        console.log("Session Expired");
      }
    });
    // eslint-disable-next-line
  }, []);

  const fetchClasses = async (uid) => {
    setLoadingClass(true);
    const q = query(
      collection(db, "classes"),
      where("teacher_uuid", "==", uid)
    );
    // Set up a listener to get real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const classesData = [];
      querySnapshot.forEach((doc) => {
        classesData.push(doc.data());
      });
      // Sort classes alphabetically by title
      classesData.sort((a, b) => a.class_title.localeCompare(b.class_title));
      setClasses(classesData);
      setLoadingClass(false);
    });
    return unsubscribe;
  };

  const handleAddClass = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleAddClassSubmit = async (classDetails) => {
    setLoading(true);
    try {
      await pushDb(classDetails);
      setIsPopupOpen(false);
    } catch (error) {
      console.error("Error adding class:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleDelete = async (class_uuid) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this class?"
      );
      if (confirmed) {
        const homeworkQuery = query(
          collection(db, "homeworks"),
          where("class_uuid", "==", class_uuid)
        );
        const homeworkSnapshot = await getDocs(homeworkQuery);

        // Delete each homework document
        homeworkSnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });

        const submissionsQuery = query(
          collection(db, "submissions"),
          where("classUid", "==", class_uuid)
        );
        const submissionsSnapshot = await getDocs(submissionsQuery);

        // Delete each submission document
        const deleteSubmissionsPromises = submissionsSnapshot.docs.map((doc) =>
          deleteDoc(doc.ref)
        );
        await Promise.all(deleteSubmissionsPromises);

        await deleteAllFilesAndFolders(`${teacherUid}/${class_uuid}`);
        await deleteDoc(doc(db, "classes", class_uuid));
        console.log("Class deleted successfully.");
      } else {
        console.log("Deletion cancelled.");
      }
    } catch (error) {
      console.error("Error deleting class:", error);
    }
  };

  const deleteAllFilesAndFolders = async (folderPath) => {
    try {
      const folderRef = ref(storage, folderPath);
      const { items, prefixes } = await listAll(folderRef);
      const deleteFilePromises = items.map((item) => deleteObject(item));
      const deleteFolderPromises = prefixes.map((subFolderRef) =>
        deleteAllFilesAndFolders(subFolderRef.fullPath)
      );
      await Promise.all([...deleteFilePromises, ...deleteFolderPromises]);
    } catch (error) {
      console.error("Error deleting files and folders:", error);
    }
  };

  const [response, setResponse] = useState('');

  const handleScriptClick = () => {
    const dataToSend = { message: 'Hello, World!', id: 123 };
    fetch('https://us-central1-embark-dev-af04e.cloudfunctions.net/addmessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json()) // Parse the JSON response
      .then((data) => {
        console.log('Received data:', data);
        setResponse(data); // Handle the received data
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleDisplayUsers = async (classDetails) => {
    alert("This is test message.");  
  };

  const pushDb = async (data) => {
    const formattedStartDate = format(new Date(data.startDate), "yyyy-MM-dd");
    const formattedStartTime = format(new Date(data.startTime), "HH:mm");
    const formattedEndDate = format(new Date(data.endDate), "yyyy-MM-dd");
    const formattedEndTime = format(new Date(data.endTime), "HH:mm");

    // Then save formattedStartDate and formattedStartTime to the database
    await setDoc(doc(db, "classes", data.uuid), {
      class_uuid: data.uuid,
      class_status: "Active",
      teacher_uuid: teacherUid,
      class_title: data.title,
      class_description: data.description,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      start_time: formattedStartTime,
      end_time: formattedEndTime,
      fees: data.feeAmount,
      currency: data.feeCurrency,
      fee_schedule: data.feeSchedule,
      class_recurrence: data.classRecurrence,
      recurrence: data.recurrence,
      student_uuids: [],
      students: {},
      homeworks: [],
    });
  };
  return (
    <div className="td-container">
      <div className="td-navbar">
        <NavBar name={displayName} role={"Teacher"} />
      </div>
      <div className="td-add-class-btn-holder">
        <Button
          className="td-add-class-btn"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddClass}
          style={{ marginRight: "10px" }}
        >
          Add Class
        </Button>
        <Button
          className="td-add-class-btn"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleScriptClick}
          style={{ marginRight: "10px" }}
        >
          Run Scripts
        </Button>

        <Button
          className="td-add-class-btn"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleDisplayUsers}
          style={{ marginRight: "10px" }}
        >
          USER LIST
        </Button>
            
      </div>
      <div className="td-class-card">
        {loadingClass ? (
          <CircularProgress style={{ color: "white", marginLeft: "50%" }} />
        ) : (
          classes.length === 0 ? (
            <Box textAlign="center" mt={5}>
              <Typography variant="h5" style={{ margin: "20px", color: "white" }}>
                No classes yet...
              </Typography>
            </Box>
          ) : (
            <Grid style={{ marginLeft: "20px", marginRight: "20px" }} item xs={9}>
              <Grid container spacing={2}>
                {classes.map((cls) => (
                  <Grid key={cls.class_uuid} item xs={12} sm={6} md={4} lg={3}>
                    <ClassCard
                      role={"teacher"}
                      classObj={cls}
                      handleDelete={handleDelete}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )
        )}
      </div>
      <ClassPopup
        isOpen={isPopupOpen}
        onClose={handlePopupClose}
        onSubmit={handleAddClassSubmit}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
}

export default TeacherDashboard;
