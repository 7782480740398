import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  Box,
  Grid,
  List,
  ListItem,
  TextField,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from "@mui/material";
import { auth, storage, db } from "../firebase/firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  getMetadata,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  deleteDoc,
  deleteField,
} from "firebase/firestore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ReactPlayer from "react-player";
import Tooltip from "@mui/material/Tooltip";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Link } from "react-router-dom";
import HomeworkEvaluatePopup from "./homework_evaluate_popup";

const HomeworkSubmitPopup = ({ isOpen, onClose, homeworkObj }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [attachmentList, setAttachmentList] = useState([]);
  const [evaluatePopupOpen, setEvaluatePopupOpen] = useState(false);
  const [classAssistantId, setClassAssistantId] = useState(null);
  const teacherUid = homeworkObj.teacher_uuid;
  const classUid = homeworkObj.class_uuid;
  const homeworkUid = homeworkObj.homework_uuid;
  const currentStudentUid = auth.currentUser.uid;

  useEffect(() => {
    const fetchClassAssistant = async () => {
      try {
        const classDocRef = doc(db, "classes", homeworkObj.class_uuid);
        const classDocSnapshot = await getDoc(classDocRef);
        if (classDocSnapshot.exists()) {
          const classData = classDocSnapshot.data();
          const assistantId = Object.keys(classData.students).find(
            (id) => classData.students[id].classAssistant
          );
          setClassAssistantId(assistantId || "");
        }
      } catch (error) {
        console.error("Error fetching class assistant:", error);
      }
    };
    fetchClassAssistant();
    fetchFiles();
    fetchAttachments();
  }, []);

  const fetchFiles = async () => {
    setLoading(true);
    const studentUid = auth.currentUser.uid;
    const filesData = homeworkObj.students[studentUid].files;

    try {
      const fileList = await Promise.all(
        Object.keys(filesData).map(async (fileUuid) => {
          const fileData = filesData[fileUuid];
          return {
            name: fileData.name,
            fileUuid: fileUuid,
            grade: fileData.grade || null,
            url: fileData.url,
            duration: fileData.duration || null,
            created_date: fileData.created_date || null,
          };
        })
      );
      setFileList(fileList);

      const currentStatus = homeworkObj.students[studentUid].homework_status;

      if (currentStatus !== "Graded") {
        if (fileList.length > 0) {
          await updateHomeworkStatus(studentUid, "Submitted");
        } else {
          await updateHomeworkStatus(studentUid, "Pending");
        }
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAttachments = async () => {
    setLoading(true);
    try {
      const attachmentList = await Promise.all(
        homeworkObj.attachments.map(async (attachment, index) => {
          const attachmentData = {
            name: attachment.name,
            index: index,
            url: attachment.url,
          };
          return attachmentData;
        })
      );
      setAttachmentList(attachmentList);
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
    }
  };

  const getAudioDuration = (fileUrl) => {
    console.log(fileUrl);
    return new Promise((resolve, reject) => {
      const media = document.createElement("video");
      media.src = fileUrl;
      media.onloadedmetadata = () => {
        resolve(media.duration);
      };
      media.onerror = (error) => {
        console.error("Error loading media metadata:", error);
        resolve(null);
      };
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(
      (file) => file.type.startsWith("audio/") || file.type.startsWith("video/")
    );

    if (validFiles.length > 0) {
      setSelectedFiles(validFiles);
    } else {
      alert("You can only upload audio or video files.");
    }
  };

  const handleClose = () => {
    setSelectedFiles([]);
    setUploadProgress({});
    onClose();
  };

  const handleUpload = async () => {
    const studentUid = auth.currentUser.uid;
    const userDoc = await getDoc(doc(db, "users", studentUid));
    const userInfo = userDoc.data();

    const uploadPromises = selectedFiles.map((file, index) => {
      const fileUuid = uuidv4();
      const filename = `${teacherUid}/${classUid}/${homeworkUid}/${studentUid}/${file.name}`;
      const storageRef = ref(storage, filename);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: progress,
            }));
          },
          (error) => {
            console.error("Error uploading file:", error);
            reject(error);
          },
          async () => {
            console.log("File uploaded successfully");
            const url = await getDownloadURL(storageRef);
            const metadata = await getMetadata(storageRef);
            const created_date = metadata.timeCreated;

            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: 100,
            }));

            try {
              const duration = await getAudioDuration(url);
              const referenceFileDuration = attachmentList.length !== 0 ? await getAudioDuration(homeworkObj.attachments[0].url) : 0;

              setFileList((prevFileList) => [
                ...prevFileList,
                {
                  name: file.name,
                  fileUuid,
                  grade: null,
                  url,
                  duration,
                  created_date,
                },
              ]);

              setSelectedFiles((prevSelectedFiles) =>
                prevSelectedFiles.filter((f) => f.name !== file.name)
              );
              setUploadProgress((prevProgress) => {
                const { [file.name]: _, ...rest } = prevProgress;
                return rest;
              });

              const homeworkDocRef = doc(
                db,
                "homeworks",
                homeworkObj.homework_uuid
              );
              await updateDoc(homeworkDocRef, {
                [`students.${studentUid}.files.${fileUuid}`]: {
                  name: file.name,
                  url: url,
                  grade: null,
                  duration: duration,
                  created_date: created_date,
                },
              });


              await setDoc(doc(db, "submissions", fileUuid), {
                fileUuid: fileUuid,
                homeworkUid: homeworkUid,
                classUid: classUid,
                studentFileName: file.name,
                studentFileUrl: url,
                referenceFileName: attachmentList.length !== 0 ? attachmentList[0].name : "",
                referenceFileUrl: attachmentList.length !== 0 ? attachmentList[0].url : "",
                studentFileDuration: duration,
                referenceFileDuration: referenceFileDuration,
                studentName: userInfo.firstname + " " + userInfo.lastname,
                studentUuid: studentUid,
                gender: userInfo.gender,
                createdDate: created_date,
              });

              console.log("File information stored in Firestore");
              resolve();
            } catch (error) {
              console.error(
                "Error calculating duration or storing file information:",
                error
              );
              reject(error);
            }

            await updateHomeworkStatus(studentUid, "Submitted");
          }
        );
      });
    });

    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const handleDelete = async (fileName, fileUuid) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this attachment?"
    );
    if (confirmation) {
      const studentUid = auth.currentUser.uid;
      const filePath = `${teacherUid}/${classUid}/${homeworkUid}/${studentUid}/${fileName}`;
      const fileRef = ref(storage, filePath);
      let updatedFileList;

      try {
        // Delete the file from Firebase Storage
        await deleteObject(fileRef);
        setFileList((prevFileList) =>
          updatedFileList = prevFileList.filter((file) => file.fileUuid !== fileUuid)
        );
        await updateDoc(doc(db, "homeworks", homeworkObj.homework_uuid), {
          [`students.${studentUid}.files.${fileUuid}`]: deleteField(),
        });
        console.log("Deleted successfully from storage and frestore");

        await deleteDoc(doc(db, "submissions", fileUuid));
        console.log("Deleted successfully from submittion");
      } catch (error) {
        console.error("Error deleting file:", error);
      }
      if(updatedFileList.length === 0){
        await updateHomeworkStatus(studentUid, "Pending")
      }
    }
  };

  const updateHomeworkStatus = async (studentUid, status) => {
    const homeworkDocRef = doc(db, "homeworks", homeworkObj.homework_uuid);
    try {
      const studentData = homeworkObj.students[studentUid];
      if (studentData.homework_status !== "Graded") {
        studentData.homework_status = status;

        await updateDoc(homeworkDocRef, {
          [`students.${studentUid}.homework_status`]: status,
        });

        console.log("Homework status updated to:", status);
      }
    } catch (error) {
      console.error("Error updating homework status:", error);
    }
  };

  const handleAudioClick = (url) => {
    setAudioUrl(url);
  };

  const handleCloseAudio = () => {
    setAudioUrl("");
  };
  console.log(fileList);

  const handleOpenEvaluatePopup = () => {
    setEvaluatePopupOpen(true);
  };

  const handleCloseEvaluatePopup = () => {
    setEvaluatePopupOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Submit Homework</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <TextField
            label="Title"
            variant="outlined"
            value={homeworkObj.homework_title}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            style={{ marginRight: "10px", flex: "3" }}
          />
          <TextField
            label="Grade"
            variant="outlined"
            value={homeworkObj.students[auth.currentUser.uid].homework_grade}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            style={{ flex: "2" }}
          />
        </div>
        <div>
          <Typography sx={{ marginLeft: "12px", fontWeight: "bold" }}>
            Teacher attachments:{" "}
          </Typography>
          {attachmentList.length === 0 ? (
            <Typography align="center">No attachments</Typography>
          ) : (
            <List>
              {attachmentList.map((attachment, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => handleAudioClick(attachment.url)}
                >
                  <ListItemText
                    primary={attachment.name}
                    primaryTypographyProps={{ noWrap: true }} // Ensures filename doesn't overflow
                    style={{ marginRight: "auto" }} // Pushes the text to the left
                  />
                  <ListItemSecondaryAction style={{ paddingRight: 8 }}>
                    <Tooltip title="Download">
                      <IconButton
                        edge="end"
                        aria-label="download"
                        component={Link}
                        to={attachment.url}
                        target="_blank"
                        download
                        style={{ padding: 8 }} // Adjust padding as needed
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </div>
        <div>
          <TextField
            margin="dense"
            label="Teacher Comment"
            type="text"
            name="description"
            multiline
            rows={5}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            value={homeworkObj.students[auth.currentUser.uid].teacherComment}
          />
        </div>
        <input
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="file-input"
          multiple
        />
        {selectedFiles.length > 0 && (
          <div style={{ marginBottom: "20px" }}>
            {selectedFiles.map((file) => (
              <div
                key={file.name}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <p>{file.name}</p>
                <LinearProgress
                  sx={{ flexGrow: 1, marginLeft: "10px" }}
                  variant="determinate"
                  value={uploadProgress[file.name] || 0}
                />
                <p style={{ marginLeft: "10px" }}>
                  {uploadProgress[file.name]
                    ? `${Math.round(uploadProgress[file.name])}%`
                    : "Start Upload"}
                </p>
              </div>
            ))}
          </div>
        )}
        <List>
          {fileList.map((file) => (
            <ListItem key={file.index}>
              <ListItemText
                primary={file.name}
                primaryTypographyProps={{ noWrap: true }} // Ensures filename doesn't overflow
                style={{ marginRight: "40px" }} // Pushes the text to the left
              />
              <ListItemSecondaryAction>
                {file.grade ? (
                  <p style={{ marginRight: "4px" }}>Grade: {file.grade}</p>
                ) : (
                  <Grid container alignItems="center">
                    <Box mr={2}>
                      <Link to={file.url} target="_blank" download>
                        <IconButton edge="end" aria-label="download">
                          <CloudDownloadIcon />
                        </IconButton>
                      </Link>
                    </Box>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDelete(file.name, file.fileUuid)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        {new Date() <= new Date(homeworkObj.homework_end_date) && (
          <Box display="flex" alignItems="center" mb={2}>
            <label htmlFor="file-input">
              <Button
                variant="contained"
                component="span"
                startIcon={<AddIcon />}
                style={{ marginRight: "10px" }}
              >
                Add File
              </Button>
            </label>
            {classAssistantId === currentStudentUid && (
              <Button
                variant="contained"
                style={{ marginLeft: "10px" }}
                onClick={handleOpenEvaluatePopup}
              >
                Evaluate Students
              </Button>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleUpload}
          disabled={
            selectedFiles.length === 0 ||
            Object.values(uploadProgress).some(
              (progress) => progress > 0 && progress <= 100
            )
          }
        >
          Upload
        </Button>
      </DialogActions>
      <Dialog open={Boolean(audioUrl)} onClose={handleCloseAudio}>
        <DialogContent>
          <ReactPlayer
            url={audioUrl}
            controls={true}
            width="100%"
            height="100%"
            onError={(e) => console.error("ReactPlayer error:", e)}
          />
        </DialogContent>
      </Dialog>
      <HomeworkEvaluatePopup
        isOpen={evaluatePopupOpen}
        onClose={handleCloseEvaluatePopup}
        homeworkObj={homeworkObj}
        ClassAssistant={classAssistantId}
      />
    </Dialog>
  );
};

export default HomeworkSubmitPopup;
