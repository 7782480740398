import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Chip, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebase";

const settings = [
  { name: "Profile", path: "" },
  { name: "Forgot Password", path: "" },
  { name: "Logout", path: "" },
];

function NavBar({ name, role }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    const response = window.confirm("Are you sure you want to logout ?");
    if (response) {
      signOut(auth)
        .then(() => {
          navigate("/");
        })
        .catch((error) => {});
    }
  };

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const drawerList = () => (
    <Box
      sx={{
        width: 225,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ padding: 2 }}>
        <Typography
          variant="h4"
          noWrap
          component="div"
          sx={{
            fontFamily: "Cocon",
            fontWeight: 700,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Embark
        </Typography>
      </Box>
      <List>
        <ListItem
          button
          onClick={() =>
            navigate(
              role === "Student" ? "/studentDashboard" : "/teacherDashboard",
              {
                state: { role: role === "Student" ? "Student" : "Teacher" },
              }
            )
          }
        >
          <ListItemText
            primary={`${role === "Student" ? "Student" : "Teacher"} Dashboard`}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => navigate("/analyticsDashboard", { state: { role } })}
        >
          <ListItemText primary="Analytics Dashboard" />
        </ListItem>
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <List>
        {settings.map((setting, index) => (
          <ListItem
            button
            key={index}
            onClick={() => setting.name === "Logout" && handleLogout()}
          >
            <ListItemText primary={setting.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" style={{ backgroundColor: "black" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ marginRight: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            {drawerList()}
          </Drawer>
          <Typography
            variant="h4"
            noWrap
            component="a"
            sx={{
              fontFamily: "Cocon",
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Embark
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 0 }}>
            {role && (
              <Chip
                label={`${role} Dashboard`}
                sx={{
                  backgroundColor: "white",
                  marginRight: "20px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                }}
              />
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;
