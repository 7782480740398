import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { storage, db } from "../firebase/firebase";
import { ref, getDownloadURL, getMetadata } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";
import ReactPlayer from "react-player";
import {
  getDoc,
  doc,
  updateDoc,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Link } from "react-router-dom";

function HomeworkEvaluatePopup({
  isOpen,
  onClose,
  homeworkObj,
  ClassAssistant,
}) {
  const [selectedStudent, setSelectedStudent] = useState("");
  const [overallGrade, setOverallGrade] = useState("");
  const [comments, setComments] = useState("");
  const [students, setStudents] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [fileInfoDialogOpen, setFileInfoDialogOpen] = useState(false);
  const [fileInfoDialogContent, setFileInfoDialogContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [individualGrades, setIndividualGrades] = useState({});
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);

  useEffect(() => {
    const q = query(
      collection(db, "homeworks"),
      where("homework_uuid", "==", homeworkObj.homework_uuid)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const homeworkData = doc.data();
        if (homeworkData && homeworkData.students) {
          const fetchedStudents = Object.keys(homeworkData.students).map(
            (studentId) => ({
              id: studentId,
              name: homeworkData.students[studentId].name,
              teacherComment:
                homeworkData.students[studentId].teacherComment || "",
              homework_grade:
                homeworkData.students[studentId].homework_grade || "",
              homework_status:
                homeworkData.students[studentId].homework_status || "Pending",
              files: homeworkData.students[studentId].files || {},
            })
          );

          const filteredStudents = ClassAssistant
            ? fetchedStudents.filter((student) => student.id !== ClassAssistant)
            : fetchedStudents;
          // Sort the students alphabetically by name
          filteredStudents.sort((a, b) => a.name.localeCompare(b.name));
          setStudents(filteredStudents);
        } else {
          console.error("No students found in the homework document");
        }
      });
    });
    return unsubscribe;
  }, [homeworkObj.homework_uuid, ClassAssistant]);

  useEffect(() => {
    const grades = Object.values(individualGrades)
      .map(parseFloat)
      .filter((grade) => !isNaN(grade));
    if (grades.length > 0) {
      const totalGrade = grades.reduce((acc, curr) => acc + curr, 0);
      const averageGrade = totalGrade / grades.length;
      setOverallGrade(averageGrade.toFixed(2)); // Limit to 2 decimal places
    }
    else {
      setOverallGrade(""); // Set overall grade to empty if no valid grades are present
    }
  }, [individualGrades]);

  const fetchFiles = async (studentId, studentData = null) => {
    setLoading(true);
    const studentUid = studentId;
    const filesData = homeworkObj.students[studentUid].files;
    if (filesData !== undefined) {
      try {
        const tempFileList = await Promise.all(
          Object.keys(filesData).map(async (fileUuid) => {
            const fileData = filesData[fileUuid];
            return {
              name: fileData.name,
              fileUuid: fileUuid,
              grade: fileData.grade,
              url: fileData.url,
              duration: fileData.duration,
              created_date: fileData.created_date,
            };
          })
        );
        
        setFileList(tempFileList);

        const totalDuration = tempFileList.reduce(
          (acc, file) => acc + file.duration,
          0
        );
        setTotalTimeSpent(totalDuration);

        if (studentData) {
          const grades = {};
          for (const file of tempFileList) {
            grades[file.fileUuid] =
              studentData.files?.[file.fileUuid]?.grade || "";
          }
          setIndividualGrades(grades);
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setLoading(false); // Reset loading state after fetching files
      }
    }else{
      setLoading(false);
      setFileList([]);
    }
  };

  const handleAccordionChange = (panel) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
    if (isExpanded) {
      const student = students.find((student) => student.id === panel);
      if (student) {
        setSelectedStudent(student.id);
        setComments(student.teacherComment || "");
        setOverallGrade(student.homework_grade || "");
        fetchFiles(student.id, student);
      }
    }
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const handleGradeChange = (event, fileUuid) => {
    const { value } = event.target;
    const grade = Math.min(
      Math.max(parseFloat(value), 0),
      parseFloat(homeworkObj.total_grade)
    );
    setIndividualGrades((prevGrades) => ({
      ...prevGrades,
      [fileUuid]: isNaN(grade) ? "" : grade.toString(),
    }));
  };
  console.log(individualGrades);

  const handleSave = async (student_uuid) => {
    try {
      const homeworkDocRef = doc(db, "homeworks", homeworkObj.homework_uuid);
      const homeworkDocSnapshot = await getDoc(homeworkDocRef);
      const homeworkDocData = homeworkDocSnapshot.data();
      if (homeworkDocData) {
        const studentData = homeworkDocData.students[student_uuid];
        studentData.teacherComment = comments;
        studentData.homework_grade = overallGrade;
  
        // Update files and grades for each attachment
        for (const [fileUuid, grade] of Object.entries(individualGrades)) {
          if (studentData.files && studentData.files[fileUuid]) {
            studentData.files[fileUuid].grade = grade;
            await updateDoc(homeworkDocRef, {
              [`students.${student_uuid}.files.${fileUuid}.grade`]: grade,
            });
          }
        }
  
        // Determine if the status should be "Pending" or "Graded"
        const areAllGradesEmpty =
          overallGrade === "" &&
          Object.values(individualGrades).every((grade) => grade === "");
  
        studentData.homework_status = areAllGradesEmpty ? "Pending" : "Graded";
  
        // Update the database with the new status and grades
        await updateDoc(homeworkDocRef, {
          [`students.${student_uuid}.homework_grade`]: overallGrade,
          [`students.${student_uuid}.homework_status`]: studentData.homework_status,
          [`students.${student_uuid}.teacherComment`]: comments,
        });
  
        window.alert("Successfully saved");
  
        // Update local state
        setStudents((prevStudents) =>
          prevStudents.map((student) =>
            student.id === student_uuid
              ? {
                  ...student,
                  teacherComment: comments,
                  homework_grade: overallGrade,
                  homework_status: studentData.homework_status, // Update local state with new status
                  files: {
                    ...student.files,
                    ...Object.fromEntries(
                      Object.entries(individualGrades).map(
                        ([fileUuid, grade]) => [fileUuid, { grade }]
                      )
                    ),
                  },
                }
              : student
          )
        );
      } else {
        console.error("Student data not found in homework collection");
      }
    } catch (error) {
      console.error("Error updating student object:", error);
    }
  };    

  const fetchAudioDuration = async (filePath) => {
    const fileRef = ref(storage, filePath);
    const url = await getDownloadURL(fileRef);
    return new Promise((resolve, reject) => {
      const audio = new Audio(url);
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
      audio.onerror = reject;
    });
  };

  const displayInfo = async (filePath, fileName) => {
    try {
      const fileRef = ref(storage, filePath);
      const metadata = await getMetadata(fileRef);
      const duration = await fetchAudioDuration(filePath);
      const minutes = Math.floor(duration / 60);
      const seconds = Math.floor(duration % 60);
      const timeCreated = new Date(metadata.timeCreated).toLocaleString();
      const timeUpdated = new Date(metadata.updated).toLocaleString();
      const fileSizeMB = (metadata.size / (1024 * 1024)).toFixed(2);
      const content = (
        <div>
          <div style={{ wordBreak: "break-all" }}>File name: {fileName}</div>
          <div>Date created: {timeCreated}</div>
          <div>Date Updated: {timeUpdated}</div>
          <div>
            Duration: {minutes} minutes {seconds} seconds
          </div>
          <div>File Size: {fileSizeMB} MB</div>
        </div>
      );
      setFileInfoDialogContent(content);
      setFileInfoDialogOpen(true);
    } catch (error) {
      console.error("Error fetching file information:", error);
    }
  };

  const handleAudioClick = (url) => {
    console.log(url);
    setAudioUrl(url);
    console.log("inside function" + Boolean(audioUrl));
  };

  const handleCloseAudio = () => {
    setAudioUrl("");
  };

  const handleCloseFileInfoDialog = () => {
    setFileInfoDialogOpen(false);
  };

  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}m ${seconds}s`;
  };

  console.log("outside function" + Boolean(audioUrl));

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogContent>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          <List>
            {students.map((student, index) => (
              <Accordion
                key={student.id}
                expanded={expanded === student.id}
                onChange={handleAccordionChange(student.id)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="subtitle1">{student.name}</Typography>

                    {expanded !== student.id && (
                      <Grid item style={{ textAlign: "right" }}>
                        <Chip
                          label={student.homework_status}
                          style={{
                            color: "white",
                            backgroundColor: "green",
                          }}
                        />
                      </Grid>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ overflow: "auto" }}>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label={homeworkObj.homework_title}
                            fullWidth
                            variant="outlined"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <TextField
                            label="Overall Grade"
                            value={overallGrade}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Box
                            bgcolor="green"
                            borderRadius={4}
                            p={1}
                            textAlign={"center"}
                          >
                            <Typography variant="body1" color="white">
                              {
                                homeworkObj.students[selectedStudent]
                                  ?.homework_status
                              }
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <List>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      fileList.map((file, index) => (
                        <ListItem
                          key={index}
                          button
                          onClick={() => handleAudioClick(file.url)}
                        >
                          <ListItemText
                            primary={file.name}
                            primaryTypographyProps={{ noWrap: true }}
                            style={{ marginRight: "150px" }}
                          />
                          <ListItemSecondaryAction>
                            <Grid container alignItems="center">
                              <Box mr={2}>
                                <TextField
                                  label="Grade"
                                  value={individualGrades[file.fileUuid]}
                                  onChange={(event) =>
                                    handleGradeChange(event, file.fileUuid)
                                  }
                                  size="small"
                                  style={{
                                    width: "80px",
                                    height: "40px",
                                  }}
                                />
                              </Box>
                              <Box mr={2}>
                                <Link to={file.url} target="_blank" download>
                                  <IconButton edge="end" aria-label="download">
                                    <CloudDownloadIcon />
                                  </IconButton>
                                </Link>
                              </Box>
                              <Box>
                                <IconButton
                                  edge="end"
                                  aria-label="info"
                                  onClick={() =>
                                    displayInfo(file.url, file.name, student.id)
                                  }
                                >
                                  <InfoIcon />
                                </IconButton>
                              </Box>
                            </Grid>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))
                    )}
                  </List>

                  {homeworkObj.students[selectedStudent]?.homework_status ===
                    "Submitted" ||
                  homeworkObj.students[selectedStudent]?.homework_status ===
                    "Graded" ? (
                    <Typography align="right">
                      Total Time: {formatDuration(totalTimeSpent)}
                    </Typography>
                  ) : null}

                  <TextField
                    margin="dense"
                    label="Comments"
                    type="text"
                    name="description"
                    multiline
                    rows={6}
                    fullWidth
                    value={comments}
                    onChange={handleCommentsChange}
                  />

                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSave(student.id);
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                  >
                    Save
                  </Button>
                </AccordionDetails>
              </Accordion>
            ))}
            {students.length === 0 && (
              <Typography variant="body1">No students available</Typography>
            )}
          </List>
        </div>
      </DialogContent>
      <Dialog open={Boolean(audioUrl)} onClose={handleCloseAudio}>
        <DialogContent>
          <ReactPlayer
            url={audioUrl}
            controls={true}
            width="100%"
            height="100%"
            onError={(e) => console.error("ReactPlayer error:", e)}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={fileInfoDialogOpen} onClose={handleCloseFileInfoDialog}>
        <DialogTitle>File Information</DialogTitle>
        <DialogContent>{fileInfoDialogContent}</DialogContent>
      </Dialog>
    </Dialog>
  );
}

export default HomeworkEvaluatePopup;
