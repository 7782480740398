import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Typography,
  Button,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { collection, query, doc, updateDoc, where, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase";

const ClassAssistantPopup = ({ open, handleClose, classObj }) => {
  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prevClassAssistant, setPrevClassAssistant] = useState(null);
  const class_uuid = classObj.class_uuid;

  useEffect(() => {
    if (!open) return;

    const q = query(collection(db, "classes"), where("class_uuid", "==", class_uuid));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const students = [];
      querySnapshot.forEach((doc) => {
        const studentData = doc.data().students || {};
        const studentUUIDs = doc.data().student_uuids || [];

        studentUUIDs.forEach((uuid) => {
          if (studentData[uuid]) {
            const student = studentData[uuid];
            const classAssistant = student.classAssistant !== undefined ? student.classAssistant : false;
            students.push({
              name: student.name,
              uuid: uuid,
              classAssistant: classAssistant,
            });
          }
        });

        console.log(students);
      });

      setEnrolledStudents(students);
      const prevAssistant = students.find(student => student.classAssistant);
      setPrevClassAssistant(prevAssistant ? prevAssistant.uuid : null);
      setSelectedStudent(prevAssistant ? prevAssistant.uuid : null);
    });

    return () => unsubscribe();
  }, [class_uuid, open]);

  const handleCloseDialog = () => {
    setLoading(false);
    handleClose();
  };

  const handleSubmitDialog = async () => {
    setLoading(true);

    try {
      const classRef = doc(db, "classes", class_uuid);
      console.log("prevClassAssistant",prevClassAssistant);

      // If no student is selected, set class assistant to false for all students
      if (!selectedStudent) {
        const updates = {};
        enrolledStudents.forEach(student => {
          updates[`students.${student.uuid}.classAssistant`] = false;
        });
        await updateDoc(classRef, updates);
        alert("No Class Assistant has been assigned.");
      } else {
        // Remove class assistant from the previously assigned student, if any
        const updates = {
          [`students.${selectedStudent}.classAssistant`]: true,
        };
        if (prevClassAssistant && prevClassAssistant !== selectedStudent) {
          updates[`students.${prevClassAssistant}.classAssistant`] = false;
        }
        await updateDoc(classRef, updates);
        alert("Class Assistant has been assigned.");
      }

      
      setLoading(false);
      handleCloseDialog();
    } catch (error) {
      console.error("Error updating student:", error);
      setLoading(false);
      alert("Failed to update students. Please try again.");
    }
  };

  const handleSelectStudent = (uuid) => {
    setSelectedStudent((prevSelected) => (prevSelected === uuid ? null : uuid));
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle>Class Assistant</DialogTitle>
      <DialogContent style={{ minWidth: "200px" }}>
        {enrolledStudents.map((student) => (
          <React.Fragment key={student.uuid}>
            <ListItem sx={{ padding: "8px 0px" }}>
              <ListItemAvatar>
                <Avatar>{student.name?.charAt(0).toUpperCase()}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={student.name} />
              <FormControlLabel
                control={
                  <Switch
                    name={student.name}
                    checked={selectedStudent === student.uuid}
                    onClick={() => handleSelectStudent(student.uuid)}
                  />
                }
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
        {enrolledStudents.length === 0 && (
          <Typography textAlign="center">No students enrolled yet</Typography>
        )}
      </DialogContent>
      {enrolledStudents.length > 0 && (
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button
            sx={{ width: "70px" }}
            onClick={handleSubmitDialog}
            color="primary"
          >
            {loading ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ClassAssistantPopup;
