import React, { useEffect, useState, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Grid,
  CircularProgress,
} from "@mui/material";
import { db } from "../firebase/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parse, format } from 'date-fns';

function ClassCopy({ open, handleCloseCopy, class_uuid, class_obj }) {
  const currentDate = new Date().toISOString().split("T")[0]; // Get current date in ISO format
  const [classDetails, setClassDetails] = useState({
    class_uuid: "",
    class_title: "",
    class_description: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    fees: "",
    currency: "",
    fee_schedule: "",
    class_recurrence: "",
    recurrence: [],
    students: [],
  });
  const [errors, setErrors] = useState({
    title: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    feeAmount: "",
    feeCurrency: "",
    feeSchedule: "",
    classRecurrence: "",
    recurrence: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log(class_uuid)
    if (open) {
      const getClassDetails = async () => {
        try {
          const classDocRef = doc(db, "classes", class_uuid);
          const classDocSnap = await getDoc(classDocRef);
          if (classDocSnap.exists()) {
            // Extract class details from the document data
            const classDetails = classDocSnap.data();
            const parseAndFormatTime = (time) => {
              if (!time) return null;
              const parsedTime = parse(time, "HH:mm", new Date());
              return parsedTime;
            };
            setClassDetails({
              ...classDetails,
              start_date: classDetails.start_date ? new Date(classDetails.start_date) : null,
              end_date: classDetails.end_date ? new Date(classDetails.end_date) : null,
              start_time: parseAndFormatTime(classDetails.start_time),
              end_time: parseAndFormatTime(classDetails.end_time),
            });
            // console.log('Class Details:', classDetails);
          } else {
            console.log("No such Document!");
          }
        } catch (error) {
          console.error("Error Getting document: ", error);
        }
      };
      getClassDetails();
    }
  }, [open]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
      setClassDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
  }, []);

  const handleDateChange = (name, date) => {
    setClassDetails((prevState) => ({
      ...prevState,
      [name]: date,
    }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    // Title validation
    if (!classDetails.class_title || classDetails.class_title.length > 50) {
      newErrors.title = "Title is required and should be maximum 50 characters";
      valid = false;
    } else {
      newErrors.title = "";
    }

    // Start Date validation
  if (!classDetails.start_date) {
    newErrors.startDate = "Start Date is required";
    valid = false;
  } else if (new Date(classDetails.start_date).setHours(0, 0, 0, 0) < new Date(currentDate).setHours(0,0,0,0)) {
    newErrors.startDate = "Start Date must be today or later";
    valid = false;
  } else {
    newErrors.startDate = "";
  }

  // End Date validation
  if (!classDetails.end_date) {
    newErrors.endDate = "End Date is required";
    valid = false;
  } else if (new Date(classDetails.end_date).setHours(0, 0, 0, 0) < new Date(classDetails.start_date).setHours(0, 0, 0, 0)) {
    newErrors.endDate = "End Date must be after Start Date";
    valid = false;
  } else {
    newErrors.endDate = "";
  }
      // Start Time validation
      if (!classDetails.start_time) {
        newErrors.startTime = "Start Time is required";
        valid = false;
      } else {
        newErrors.startTime = "";
      }

    // End Time validation
    if (!classDetails.end_time) {
      newErrors.endTime = "End Time is required";
      valid = false;
    } else if (new Date(classDetails.end_time) < new Date(classDetails.start_time)) {
      newErrors.endTime = "End Time must be after Start Time";
      valid = false;
    } else {
      newErrors.endTime = "";
    }

    // Fee Amount validation
    if (!classDetails.fees) {
      newErrors.feeAmount = "Fee Amount is required";
      valid = false;
    } else {
      newErrors.feeAmount = "";
    }

    // Fee Currency validation
    if (!classDetails.currency) {
      newErrors.feeCurrency = "Fee Currency is required";
      valid = false;
    } else {
      newErrors.feeCurrency = "";
    }

    // Fee Schedule validation
    if (!classDetails.fee_schedule) {
      newErrors.feeSchedule = "Fee Schedule is required";
      valid = false;
    } else {
      newErrors.feeSchedule = "";
    }

    // Class Recurrence validation
    if (!classDetails.class_recurrence) {
      newErrors.classRecurrence = "Class Recurrence is required";
      valid = false;
    } else {
      newErrors.classRecurrence = "";
    }

    // Recurrence validation
    if (
      classDetails.class_recurrence === "Weekly" &&
      classDetails.recurrence.length === 0
    ) {
      newErrors.recurrence = "Recurrence is required for Weekly class";
      valid = false;
    } else {
      newErrors.recurrence = "";
    }

    setErrors(newErrors);
    return valid;
  };

  const clearFields = () => {
    setClassDetails({
      class_title: "",
      class_description: "",
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
      fees: "",
      currency: "",
      fee_schedule: "",
      class_recurrence: "",
      recurrence: [],
      students: [],
    });

    setErrors({
      title: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      feeAmount: "",
      feeCurrency: "",
      feeSchedule: "",
      classRecurrence: "",
      recurrence: "",
    });
  };

  const handleAddClassSubmit = async () => {
    try {
      if (validateForm()) {
        setLoading(true);
        const data = { ...classDetails, uuid: uuidv4() };
        await pushDb(data);
        handleClose();
      }
    } catch (error) {
      console.error("Error adding class:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const pushDb = async (data) => {
    const formattedStartDate = format(new Date(data.start_date), "yyyy-MM-dd");
    const formattedStartTime = format(new Date(data.start_time), "HH:mm");
    const formattedEndDate = format(new Date(data.end_date), "yyyy-MM-dd");
    const formattedEndTime = format(new Date(data.end_time), "HH:mm");
    await setDoc(doc(db, "classes", data.uuid), {
      class_uuid: data.uuid,
      class_status: "Active",
      teacher_uuid: class_obj.teacher_uuid,
      class_title: data.class_title,
      class_description: data.class_description,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      start_time: formattedStartTime,
      end_time: formattedEndTime,
      fees: data.fees,
      currency: data.currency,
      fee_schedule: data.fee_schedule,
      class_recurrence: data.class_recurrence,
      recurrence: data.recurrence,
      student_uuids: data.student_uuids,
      students: data.students,
      homeworks: [],
    });
  };

  const handleClose = () => {
    clearFields();
    handleCloseCopy();
  };

  return (
    <Dialog open={open} onClose={handleCloseCopy}>
      <DialogTitle>Copy Class</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          type="text"
          name="class_title"
          fullWidth
          value={classDetails.class_title}
          onChange={handleInputChange}
          error={Boolean(errors.title)}
          helperText={errors.title}
        />
        <TextField
          margin="dense"
          label="Description"
          type="text"
          name="class_description"
          multiline
          rows={3}
          fullWidth
          value={classDetails.class_description}
          onChange={handleInputChange}
        />
        <Grid item xs={6} style={{ height: '.5em' }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                margin="dense"
                id="start_date"
                type="date"
                name="homework_start_date"
                fullWidth
                inputProps={{ min: currentDate }}
                label="Start Date"
                value={classDetails.start_date}
                error={Boolean(errors.startDate)}
                helperText={errors.startDate}
                InputLabelProps={{ shrink: true }}
                onChange={(date) => handleDateChange("start_date", date)}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: Boolean(errors.startDate),
                    helperText: errors.startDate,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={classDetails.end_date}
                label="End Date"
                onChange={(date) => handleDateChange("end_date", date)}
                inputFormat="mm/dd/yyyy"
                InputLabelProps={{ shrink: true }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    name: 'endDate',
                    error: Boolean(errors.endDate),
                    helperText: errors.endDate,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ height: '.5em' }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                margin="dense"
                label="Start Time"
                type="time"
                name="start_time"
                fullWidth
                value={classDetails.start_time}
                error={Boolean(errors.startTime)}
                helperText={errors.startTime}
                InputLabelProps={{ shrink: true }}
                onChange={(time) => handleDateChange("start_time", time)}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: Boolean(errors.startTime),
                    helperText: errors.startTime,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                margin="dense"
                label="End Time"
                type="time"
                name="end_time"
                value={classDetails.end_time}
                error={Boolean(errors.endTime)}
                helperText={errors.endTime}
                InputLabelProps={{ shrink: true }}
                onChange={(time) => handleDateChange("end_time", time)}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: Boolean(errors.endTime),
                    helperText: errors.endTime,
                    InputProps: { min: currentDate },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Fee Amount"
              type="number"
              name="fees"
              fullWidth
              value={classDetails.fees}
              onChange={handleInputChange}
              error={Boolean(errors.feeAmount)}
              helperText={errors.feeAmount}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              margin="dense"
              error={Boolean(errors.feeCurrency)}
            >
              <InputLabel>Fee Currency</InputLabel>
              <Select
                value={classDetails.currency}
                onChange={handleInputChange}
                name="currency"
                label="feeCurrency"
              >
                <MenuItem value="INR">INR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <FormControl
          fullWidth
          margin="dense"
          error={Boolean(errors.feeSchedule)}
        >
          <InputLabel>Fee Schedule</InputLabel>
          <Select
            value={classDetails.fee_schedule}
            onChange={handleInputChange}
            label="feeSchedule"
            name="fee_schedule"
          >
            <MenuItem value="Monthly">Monthly</MenuItem>
            <MenuItem value="Quarterly">Quarterly</MenuItem>
            <MenuItem value="Half-Yearly">Half-Yearly</MenuItem>
            <MenuItem value="Annually">Annually</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
          error={Boolean(errors.classRecurrence)}
        >
          <InputLabel>Class Recurrence</InputLabel>
          <Select
            value={classDetails.class_recurrence}
            onChange={handleInputChange}
            name="class_recurrence"
            label="classRecurrence"
          >
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
          </Select>
        </FormControl>
        {classDetails.class_recurrence === "Weekly" && (
          <FormControl
            fullWidth
            margin="dense"
            error={Boolean(errors.recurrence)}
          >
            <InputLabel>Recurrence</InputLabel>
            <Select
              multiple
              value={classDetails.recurrence}
              onChange={handleInputChange}
              name="recurrence"
              label="recurrence"
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="Sunday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Sunday")}
                />
                <ListItemText primary="Sunday" />
              </MenuItem>
              <MenuItem value="Monday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Monday")}
                />
                <ListItemText primary="Monday" />
              </MenuItem>
              <MenuItem value="Tuesday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Tuesday")}
                />
                <ListItemText primary="Tuesday" />
              </MenuItem>
              <MenuItem value="Wednesday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Wednesday")}
                />
                <ListItemText primary="Wednesday" />
              </MenuItem>
              <MenuItem value="Thursday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Thursday")}
                />
                <ListItemText primary="Thursday" />
              </MenuItem>
              <MenuItem value="Friday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Friday")}
                />
                <ListItemText primary="Friday" />
              </MenuItem>
              <MenuItem value="Saturday">
                <Checkbox
                  checked={classDetails.recurrence.includes("Saturday")}
                />
                <ListItemText primary="Saturday" />
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          sx={{ width: "70px" }}
          onClick={handleAddClassSubmit}
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ClassCopy;
